import React, { createContext, useContext, useEffect, useState } from "react";
import { MEAL } from "../types";
import {deleteSingleMeal, getMeals, save} from '../api/index';

type MealContextType = {
  meals: MEAL[] | null;
  isSaving: boolean;
  isDeleting: boolean;
  fetchMeals: (token: string) => Promise<void>;
  saveMeal: (meal: MEAL, token: string) => Promise<void>;
  deleteMeal: (mealId: string) => Promise<void>;
};

const MealContext = createContext<MealContextType>({
  meals: null,
  isSaving: false,
  isDeleting: false,
  fetchMeals: async () => {},
  saveMeal: async () => {},
  deleteMeal: async () => {},
});

type Props = {
  children?: React.ReactNode;
};

const MealProvider: React.FC<Props> = ({ children }) => {
  const [meals, setMeals] = useState<MEAL[] | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const fetchMeals = async (token: string) => {
    console.log("fetchMeals called");
    try {
      const response = await getMeals(token);
      console.log("response ",response);
      setMeals(response);
    } catch (error) {
      console.error("Failed to fetch meals:", error);
    }
  };

  const saveMeal = async (meal: MEAL, token: string) => {
    setIsSaving(true);
    try {
      const response = await save(meal,token);
    } catch (error) {
      console.error("Failed to save meal:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const deleteMeal = async (mealId: string) => {
    setIsDeleting(true);
    try {
      const response = await deleteSingleMeal(mealId);
    } catch (error) {
      console.error("Failed to save meal:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    // fetchMeals();
  }, []);

  return (
    <MealContext.Provider value={{ meals, isSaving, deleteMeal, isDeleting, fetchMeals, saveMeal }}>
      {children}
    </MealContext.Provider>
  );
};

export const useMeals = () => useContext(MealContext);

export { MealContext, MealProvider };
