import React from "react";
import { CheckIn } from "../../CheckIns";
import { format, isToday } from "date-fns";
import {
    tdStyles,
    tableRow,
    styles,
  } from "./CheckInRow.css";

type Props = {
  checkIn: CheckIn;
  setSelectedCheckIn: (checkIn: CheckIn) => void;
};

const CheckInRow: React.FC<Props> = ({ checkIn, setSelectedCheckIn }: Props) => {
  const getIsToday = (date: string) => isToday(new Date(date));
  return (
    <tr
      css={[tableRow, getIsToday(checkIn.date) ? styles.selectedTR : {}]}
      key={checkIn.id}
      onClick={() => {
        setSelectedCheckIn(checkIn);
      }}
    >
      <td css={tdStyles}>{format(new Date(checkIn.date), "dd.MM")}</td>
      <td
        css={[
          tdStyles,
          checkIn.isSuccess
            ? styles.statusTextColor.success
            : styles.statusTextColor.failure,
        ]}
      >
        {checkIn.calories}
      </td>
      <td css={tdStyles}>{checkIn.weight ? `${checkIn.weight} kg` : "-"}</td>
      <td css={[tdStyles, styles.statusTd]}>
        <div
          css={[
            styles.checkInStatus,
            checkIn.isSuccess ? {} : styles.checkInStatusFailure,
          ]}
        />
      </td>
    </tr>
  );
};

export default CheckInRow;
