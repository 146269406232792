import React, { createContext, useContext, useEffect, useState } from "react";
import { Journey } from "../api/types";

type UserLoginArguments = {token: string, email: string, name: string, id: string, is_confirmed: boolean}

type AuthContextType = {
  isAuthenticated: boolean;
  token: string | null;
  email: string | null;
  username: string | null;
  id: string | null;
  userJourney: Journey | null;
  isConfirmed: boolean;
  login: (user: UserLoginArguments, journey?: Journey) => void;
  logout: () => void;
  setUser: (user: UserLoginArguments) => void;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  token: null,
  email: null,
  username: null,
  id: null,
  userJourney: null,
  isConfirmed: false,
  login: () => {},
  logout: () => {},
  setUser: () => {},
});

type Props = {
  children?: React.ReactNode;
};

const AuthProvider: React.FC<Props> = ({ children }) => {

  const localStorageToken = localStorage.getItem("token");
  const localStorageId = localStorage.getItem("userid");

  const [isAuthenticated, setIsAuthenticated] = useState(localStorageToken ? true : false);
  const [token, setToken] = useState<string | null>(localStorageToken);
  const [email, setEmail] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [id, setId] = useState<string | null>(localStorageId);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [userJourney, setJourney] = useState<Journey | null>(null);

  const login = ({
    token, email, name, id
  }:{
    token: string, email: string, name: string, id: string
  }, journey?: Journey) => {
    setIsAuthenticated(true);
    setToken(token);
    setEmail(email);
    setUsername(name);
    setId(id);
    if(journey) 
    setJourney(journey)
    
    localStorage.setItem("userid", id);
    localStorage.setItem("token", token);
    localStorage.setItem("email", email);
    localStorage.setItem("username", name);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setEmail(null);
    setUsername(null);

    localStorage.removeItem("token");
    localStorage.removeItem("userid");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
  };

  const setUser = (user: UserLoginArguments) => {
    setToken(user.token);
    setEmail(user.email);
    setUsername(user.name);
    setId(user.id);
    setIsConfirmed(user.is_confirmed);
  }

  useEffect(() => {
    console.log("Rendering AuthProvider initially");
    if(!token) {}
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, token, email, id, username, isConfirmed, login, logout, setUser, userJourney }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider };
