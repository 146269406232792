import { MEAL } from "./types";
import { parseISO, isSameDay, setHours, setMinutes, setMilliseconds, setSeconds } from 'date-fns';

export const findMealsByDate = (meals: MEAL[], selectedDate: Date): MEAL[] => {
    console.log("findMealsByDate ", meals);
    const startOfDay = setSeconds(setMinutes(setHours(selectedDate, 0),0),0)
    return meals.filter(meal => {
        console.log("meal.date ", meal.date);
        console.log("selectedDate ", meal.date);
        console.log("startOfDay ", startOfDay);
      return isSameDay(new Date(meal.date), startOfDay);
    });
};

type MealTotals = {
    totalCalories: number;
    totalProtein: number;
    totalCarbs: number;
    totalFat: number;
};

export const calculateMealTotals = (meal: MEAL): MealTotals => {
    let totalCalories = 0;
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;
  
    meal.items.forEach(item => {
      totalCalories += item.calories * item.number_of_servings;
      totalProtein += item.protein * item.number_of_servings;
      totalCarbs += item.carbs * item.number_of_servings;
      totalFat += item.fat * item.number_of_servings;
    });
  
    return {
      totalCalories,
      totalProtein,
      totalCarbs,
      totalFat,
    };
  };

  type DayTotals = {
    totalCalories: number;
    totalProtein: number;
    totalCarbs: number;
    totalFat: number;
  };

export const calculateDayTotals = (meals: MEAL[]): DayTotals => {
    return meals.reduce<DayTotals>(
      (totals, meal) => {
        const mealTotals = calculateMealTotals(meal);
        totals.totalCalories += mealTotals.totalCalories;
        totals.totalProtein += mealTotals.totalProtein;
        totals.totalCarbs += mealTotals.totalCarbs;
        totals.totalFat += mealTotals.totalFat;
        return totals;
      },
      { totalCalories: 0, totalProtein: 0, totalCarbs: 0, totalFat: 0 }
    );
  };