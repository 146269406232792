import React, { useEffect } from "react";
import { useAuth } from "../state/context";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../global/routing/routes";

const RequireAuthentication = () => {
  const { isAuthenticated, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/${ROUTES.LOGIN}`);
    }
  }, [isAuthenticated]);

  return null;
};

export default RequireAuthentication;
