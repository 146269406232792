import { css } from "@emotion/react";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  `,
  heading: css`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  `,
  message: css`
    font-size: 18px;
    margin-bottom: 20px;
  `,
  coachName: css`
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
  `,
};

export default styles;
