import styled from "@emotion/styled";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AuthProvider } from "./domains/Auth/state/context";
import { CheckInFeedbackProvider } from "./domains/CheckIns/state/context";
import { MealProvider } from "./domains/Meals/state/context";

// Create a client
const queryClient = new QueryClient();

// You can use css prop, object styles, styled components style, ...
// whatever you want within Emotion supports.
function App() {
  return (
    <Background>
      <AuthProvider>
        <CheckInFeedbackProvider>
          <MealProvider>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <Routing />
              </BrowserRouter>
            </QueryClientProvider>
          </MealProvider>
        </CheckInFeedbackProvider>
      </AuthProvider>
    </Background>
  );
}

export default App;

const Background = styled.div`
  font-family: "Gill Sans", --apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;
