import React, { createContext, useContext, useEffect, useState } from "react";

type SetFeedbackArgs = {
  response: string, success: boolean, date: string, id: string
}

type CheckInFeedbackContextType = {
    response: string | null;
    isSuccess: boolean | null;
    date: string | null;
    id: string | null;
    setFeedback: (args: SetFeedbackArgs) => void,
    clearFeedback: () => void,
};

const CheckInFeedbackContext = createContext<CheckInFeedbackContextType>({
    response: null,
    isSuccess: null,
    date: null,
    id: null,
    setFeedback: () => {},
    clearFeedback: () => {},
});

type Props = {
  children?: React.ReactNode;
};

const CheckInFeedbackProvider: React.FC<Props> = ({ children }) => {

  const [response, setResponse] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const [id, setId] = useState<string | null>(null);

  const setFeedback = ({
    response, success, date, id
  }:{
    response: string, success: boolean, date: string, id: string
  }) => {
    setResponse(response);
    setIsSuccess(success);
    setDate(date);
    setId(id);
  };

  const clearFeedback = () => {
    setResponse(null);
    setIsSuccess(null);
    setDate(null);
    setId(null);
  };

  return (
    <CheckInFeedbackContext.Provider
      value={{ response, isSuccess, date, id, setFeedback, clearFeedback }}
    >
      {children}
    </CheckInFeedbackContext.Provider>
  );
};

export const useCheckInFeedback = () => useContext(CheckInFeedbackContext);

export { CheckInFeedbackContext, CheckInFeedbackProvider };
