import { css } from "@emotion/react";
import { theme } from "../../../../../global/styling/theming";

export const styles = {
    selectedCheckIn: css`
        position: fixed;
        top: 20%;
        background: white;
        padding: 20px;
        border-radius: 20px;
        border: 1px solid #0000007a;
        box-shadow: 4px 2px #5c5c5c;

        left: 50%;
        transform: translate(-50%, 0);
        
        z-index: 201;

        display: flex;
        flex-direction: column;
        min-width: 300px;
    `,
    content: css`
        display: flex;
    `,
    box: css`
        padding: 10px;
    `,
    boxImage: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    `,
    title: css`
        font-family: "Inter", sans-serif;
    `,
    unit: css`
        text-decoration: underline;
    `,
    buttons: css`
        display: flex;
        justify-content: space-between;
    `,
    button: css`
        border: none;
        padding: 11px 40px;
        font-size: 16px;
        font-family: ${theme.fontFamily.main} ${theme.fontFamily.secondary} sans-serif;
        font-weight: 500;
    `,
    deleteButton: css`
        background: #89302A;
        padding: 16px;
        color: white;
        border-radius: 4px;
    `,
    closeButton: css`
        background: #34B971;
        padding: 16px;
        color: white;
        border-radius: 4px;
    `,
};

