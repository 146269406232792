import React from "react";
import { css } from "@emotion/react";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 90%;
    @media (min-width: 768px) {
      width: 600px;
    }
  `,
}

type Props = {
    children: React.ReactNode;
};

const FeedbackContainer: React.FC<Props> = ({children}) => {
    return (
        <div css={styles.container}>
            {children}
        </div>
    );
};

export default FeedbackContainer;