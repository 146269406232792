import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../global/routing/routes";
import { css } from "@emotion/react";
import { useAuth } from "../domains/Auth/state/context";
import { ReactComponent as LogoSVG } from "./icons/fitmentor_logo.svg";
import config from "../config";
import FeedbackLink from "./FeedbackLink";

const styles = {
  container: css`
    background: #333;
  `,
  nav: css`
    display: none;
  `,
  logo: css`
    color: #a5ecd7;
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    font-weight: semibold;
    padding: 10px 20px;
  `,
  mainNav: css`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    align-items: center;
  `,
  mainLinks: css`
    color: white;
    margin-left: 8px;
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  `,
  feedbackLink: css`
    font-style: italic;
    text-decoration: none;
  `,
  mobileMenuContainer: css`
    @media (max-width: 768px) {
      position: relative;
    }
  `,
  mobileMenu: css`
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      background: #333;
      padding: 20px;
      width: 100px;
      right: 0;
      border-radius: 10px;
      z-index: 100;
    }
  `,
  hideMobileMenu: css`
    margin-right: 20px;
    @media (max-width: 768px) {
      display: none;
    }
  `,
  mobileMenuBtn: css`
    margin-right: 20px;
    color: white;
    text-decoration: underline;
    @media (min-width: 768px) {
      display: none;
    }
  `
};

const Navigation = () => {
  const { isAuthenticated } = useAuth();
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const toggleMobileMenu = () => {
    setShowMobileMenu(false);
  };
  return (
    <div css={styles.container}>
      <div css={styles.mainNav}>
        <Link to={ROUTES.HOME}>
          <LogoSVG style={{width: "100px", padding: "10px 20px"}} />
        </Link>
        <div>
          <span css={styles.mobileMenuBtn} onClick={() => {setShowMobileMenu(!showMobileMenu)}}>{showMobileMenu ? "Close" : "Menu"}</span>
          <div css={[styles.mobileMenuContainer]}>
              {!isAuthenticated && (
                <div css={[showMobileMenu ? styles.mobileMenu : styles.hideMobileMenu]}>
                  <Link to={false ? config.FEATURES.STRIPE_BILLING : ROUTES.SIGNUP} css={styles.mainLinks} onClick={toggleMobileMenu}>
                    Signup
                  </Link>
                  <Link to={ROUTES.LOGIN} css={styles.mainLinks} onClick={toggleMobileMenu}>
                    Login
                  </Link>
                </div>
              )}
              {isAuthenticated && (
                <div css={[showMobileMenu ? styles.mobileMenu : styles.hideMobileMenu]}>
                  <Link to={ROUTES.OVERVIEW} css={styles.mainLinks} onClick={toggleMobileMenu}>
                    Daily log
                  </Link>
                  <Link to={ROUTES.PHOTO} css={styles.mainLinks} onClick={toggleMobileMenu}>
                    Photo Estimator
                  </Link>
                  {/*                   
                    <Link to={ROUTES.CHECKINS} css={styles.mainLinks} onClick={toggleMobileMenu}>
                      Coaching
                    </Link>
                  */}
                  <Link to={ROUTES.SIGNOUT} css={styles.mainLinks} onClick={toggleMobileMenu}>
                    Sign out
                  </Link>
                </div>
              )}
            </div>
          </div>
      </div>
    </div>
  );
};

export default Navigation;
