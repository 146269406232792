import React, { useEffect } from "react";
import { styles } from "./LandingPages/Coaching/LandingPage.css";

const ThankYouPage: React.FC = () => {
  useEffect(() => {
    // @ts-ignore
    if(rdt) {
        // @ts-ignore
        rdt('track', 'SignUp', {
            "currency": "USD",
            "value": 10
        });
    }
  }, [])
  return (
    <div>
        <div>
            <div css={styles.hero}>
                <div
                    style={{marginBottom: "240px"}}
                >
                    <h2 css={styles.heroTitle}>Thank you for signing up</h2>
                    <p>We will let you know when the app is ready.</p>
                </div>

            </div>
        </div>
        <div css={styles.footer}>
            <p>FitMentor</p>
        </div>
    </div>
  );
};

export default ThankYouPage;
