import React from "react";
import { styles } from "./LandingPage.css";
import CtaButton from "../../../components/CTAButton/CTAButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../global/routing/routes";
import { css } from "@emotion/react";

const styling = {
  howToContainer: css`
    margin-bottom: 40px;
  `,
}

const Step = ({
  number,
  title,
  description,
}: {
  number: number;
  title: string;
  description: string;
}) => {
  return (
    <div className="Step" css={styles.steps.step.main}>
      <div css={styles.steps.step.container}>
        <span css={styles.steps.step.unit}>Step</span>
        <span css={styles.steps.step.number}>{number}</span>
      </div>
      <div css={styles.steps.step.contentContainer}>
        <h3 css={styles.steps.step.stepTitle}>{title}</h3>
        <p css={styles.steps.step.stepDescription}>{description}</p>
      </div>
    </div>
  );
};

const Steps = () => {
  return (
    <>
      <h3 css={styles.steps.header}>How to lose weight with FitMentor ?</h3>
      <div className="Steps" css={styles.steps.container}>
        <Step
          number={1}
          title="Pick a calorie goal"
          description="Calculate your ideal calorie deficit. Then pick a reasonable calorie goal that you can stick to. Next is to take picture of your meals. "
        />
        <Step
          number={2}
          title="Snap Your Meals"
          description="Take a photo of each meal. Our AI engine will provide an accurate calorie and macro breakdown, making it easy to track your intake."
        />
        <Step
          number={3}
          title="Monitor Your Progress"
          description="Consistently use FitMentor to stay on top of your diet. With regular tracking, you'll see progress on losing weight."
        />
      </div>
    </>
  );
};

const Old_LandingPage = (goToSignUpPage: () => void) => {
  <div css={styles.benefits.mainContainer}>
    <div css={styles.howTo}>
      {/* Steps */}
      <h3 css={styles.steps.header}>How to lose weight with FitMentor ?</h3>
      <div className="Steps" css={styles.steps.container}>
        <Step
          number={1}
          title="Pick a calorie goal"
          description="Calculate your ideal calorie deficit. Then pick a reasonable calorie goal that you can stick to. "
        />
        <Step
          number={2}
          title="Check in daily"
          description="Everyday your Coach will check in with you, and give you feedback and encouragement."
        />
        <Step
          number={3}
          title="Lose weight"
          description="Once you get consistent with your diet, you will begin to lose weight consistently as well."
        />
      </div>
    </div>
    <h3 css={styles.steps.header}>What you get with FitMentor ?</h3>
    <div css={styles.benefits.container}>
      <div css={styles.benefits.benefit}>
        <img
          css={styles.benefits.image}
          src="/personal_support.jpg"
          alt="placeholder"
        />
        <div css={styles.benefits.text}>
          <h4 css={styles.benefits.title}>
            Personal weightloss <br />
            support available 24/7
          </h4>
          <p css={styles.benefits.paragraph}>
            Your personal FitMentor coach is there for you, when{" "}
            <strong>you need it.</strong> No matter the time of the day, you
            will get instant assistance and support to stay on your path.
          </p>
        </div>
      </div>
      <div css={styles.benefits.benefit}>
        <img
          css={styles.benefits.image}
          src="/notification.jpg"
          alt="placeholder"
        />
        <div css={styles.benefits.text}>
          <h4 css={styles.benefits.title}>
            Daily checkins to <br />
            keep you on track
          </h4>
          <p css={styles.benefits.paragraph}>
            With FitMentor we will check in with you everyday. To help you stay
            consistent, so you consistent results. We will make sure you stay on
            the diet, and adjust it to ensure it is easy to maintain and make it
            a sustainable lifestyle.
          </p>
        </div>
      </div>
    </div>
    <div css={styles.benefits.container}>
      <div css={styles.benefits.benefit}>
        <img
          css={[styles.benefits.image, styles.benefits.personalImage]}
          src="/profile.jpeg"
          alt="placeholder"
        />
        <div css={styles.benefits.text}>
          <h4 css={styles.benefits.title}>
            Personalized tips to
            <br />
            help you solve problems
          </h4>
          <p css={styles.benefits.paragraph}>
            Based on your experience, struggles and issues that arise. You will
            get tailored and personal tips to overcome them and continue to make
            progress toward your healthy and fit lifestyle.
          </p>
        </div>
      </div>
      <div css={[styles.benefits.benefit, styles.benefits.lastBenefit]}>
        <div css={[styles.benefits.text, styles.benefits.lastBenefitText]}>
          <h4 css={styles.benefits.title}>
            Get your personal fitness AI coach today
          </h4>
          <button
            onClick={goToSignUpPage}
            css={[styles.ctaButton, styles.benefits.lastBenefitCTA]}
          >
            Start today
          </button>
        </div>
      </div>
      {/* Get your personal fitness AI coach today */}
    </div>
  </div>;
};

function scrollToTop() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const PhotoEstimatorLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const goToSignUpPage = () => {
    // window.open("https://tally.so/r/nroqL5", "_blank");
    // @TODO - send to sign up page
    scrollToTop();
    navigate("/" + ROUTES.SIGNUP);
  };
  return (
    <div>
      <div>
        <div css={styles.hero}>
          <div css={styles.heroContainer}>
            <div css={styles.heroTextContent}>
              <h1 css={styles.heroTitle}>
                Your <strong>AI Photo </strong>
                <br /> calorie estimator
              </h1>
              <p css={styles.heroSubTitle}>
                With FitMentor, you can track macros <br /> with only photos.
              </p>
              {<p css={styles.pricing}>10 $ per month</p>}
              <div css={styles.pricingContainer}>
                <CtaButton
                  css={styles.ctaButton}
                  onClick={goToSignUpPage}
                  text="Get your FitMentor"
                />
                <span css={styles.pricingConditions}>First month free</span>
                {/*                
                  <span css={styles.pricingConditions}>
                    No credit card required
                  </span>
                */}
              </div>
            </div>
            <div css={styles.screenshot.container}>
              <img
                css={styles.screenshot.image}
                src="/MobilePreview_v2.png"
                alt="Screenshot"
              />
            </div>
          </div>
          <div css={styles.features}>
            <div css={styles.feature}>
              <h3 css={styles.featureHeader}>Effortless Nutrition Tracking</h3>
              <p css={styles.featureParagraph}>
                No more manual logging of your meals. Just snap a picture, and
                let FitMentor provide a detailed breakdown of your macronutrient
                intake.
              </p>
            </div>
            <div css={styles.feature}>
              <h3 css={styles.featureHeader}>Comprehensive Food Database</h3>
              <p css={styles.featureParagraph}>
                FitMentor has access to an extensive food database, ensuring
                accurate calorie and macro estimations for a wide variety of
                dishes and ingredients.
              </p>
            </div>
            <div css={styles.feature}>
              <h3 css={styles.featureHeader}>AI-Powered Precision</h3>
              <p css={styles.featureParagraph}>
                FitMentor uses cutting-edge AI technology to accurately track
                and estimate the calories and macros in your food, simply by
                analyzing a photo.
              </p>
            </div>
          </div>
        </div>
        <div css={[styles.benefits.mainContainer,styling.howToContainer]}>
          <div css={styles.howTo}>
            <Steps />
          </div>
        </div>
      </div>
      <div css={styles.footer}>
        <p>FitMentor</p>
        <a href={ROUTES.PRIVACY_POLICY}>Privacy Policy</a>
      </div>
    </div>
  );
};

export default PhotoEstimatorLandingPage;
