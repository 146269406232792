import { css } from "@emotion/react";

const styles = {
  button: css`
    padding: 10px 40px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
    cursor: pointer;
    margin-top: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  `,
  success: css`
    width: 40px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  `,
  response: css`
    font-size: 22px;
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    text-align: center;
    white-space: pre-line;
    padding-bottom: 40px;
    @media (min-width: 768px) {
      padding: 0 10%;
    }
  `,
  responseButtonContainer: css`
    width: 100%;
    background: white;
    padding: 20px;
    display: flex;
    justify-content: center;
  `,
};


export default styles;