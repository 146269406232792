import React from "react";
import { loginUser, requestAutoLogin } from "../api";
import LoginForm, { LoginFormData } from "../components/Login";
import { useAuth } from "../state/context";
import { LoginResponse } from "../api/types";
import { RESPONSE_STATUSES } from "../../../global/api/types";
import { useNavigate } from "react-router-dom";
import StatusMessage from "../../../components/StatusMessage";
import { ROUTES } from "../../../global/routing/routes";

const LoginContainer = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [message, setMessage] = React.useState<string | null>(null);

  const clearMessage = () => {
    setMessage(null);
  };

  const loginFailed = () => {
    setMessage("Could not log in user");
  };

  const handleAutoLoginRequest = async (email: string) => {
    try {
      const request = await requestAutoLogin({ email });
      console.log("request ",request)
      // set message
      setMessage("Check your email for a magic link to log in.");
    } catch(error) {
      console.log("handleAutoLoginRequest error", error);
      setMessage("Could not send magic link to email");
    }
  }

  const handleSuccessFulLogin = (data: LoginResponse) => {
    if (data && data.user.token) {
      // const token = data.token;
      login({
        token: data.user.token, 
        email: data.user.email, 
        name: data.user.name,
        id: data.user.id,
        is_confirmed: data.user.is_confirmed,
      }, data.journey);
      navigate("/"+ROUTES.PHOTO);
    } else {
      loginFailed();
    }
  };

  const handleSubmit = async (formData: LoginFormData) => {
    clearMessage();
    const { status, data } = await loginUser(formData);
    const isSuccessful = status === RESPONSE_STATUSES.SUCCESS;
    if (isSuccessful && data) {
      handleSuccessFulLogin(data);
    } else {
      setMessage("Could not log in user");
    }
  };

  return (
    <>
      <StatusMessage message={message} error={!!message} />
      <LoginForm onSubmit={handleSubmit} />
    </>
  );
};

export default LoginContainer;
