import { css } from "@emotion/react";
import React from "react";
import { Link } from "react-router-dom";

const styles = {
  mainLinks: css`
    color: white;
    margin-left: 8px;
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  `,
  feedbackLink: css`
    font-style: italic;
    text-decoration: none;
  `,
  lightMode: css`
    color: #333;
  `,
};

type Props = {
    isDark?: boolean
}

const FeedbackLink: React.FC<Props> = ({isDark=true}) => {
  return (
    <Link
      to="https://tally.so/r/mY4vVz"
      css={[styles.mainLinks, isDark ? null : styles.lightMode]}
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      📝 <span css={[styles.feedbackLink]}>Feedback</span>
    </Link>
  );
};

export default FeedbackLink;
