import { css } from "@emotion/react";

export const styles = {
    container: css`
      padding: 20px;
      @media (min-width: 980px) {
        width: 750px;
        margin: auto;
      }
    `,
    filters: css`
      display: flex;
      align-items: center;
    `,
    filterLabel: css`
      margin-right: 8px;
    `,
    selectFilter: css`
      border: none;
      background: #efefef;
      padding: 4px 8px;
    `,
};