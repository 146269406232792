import config from "../../../config";
import { getLoggedInHeaders } from "../../../global/api/utils";
import { Goal } from "../GoalForm/GoalForm";

export const createGoal = async ({goal, token}: {goal: Goal, token: string}) => {
  const response = await fetch(`${config.api}/api/v1/journeys`, {
    method: "POST",
    body: JSON.stringify(goal),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to create goal");
  }

  return response.json();
};

export const updateGoal = async ({goal, goalId, token}: {goal: Goal, goalId: string, token: string}) => {
  const response = await fetch(`${config.api}/api/v1/journeys/${goalId}`, {
    method: "PUT",
    body: JSON.stringify(goal),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to create goal");
  }

  return response.json();
};
