import React from "react";
import styles from "./Input.css";

type Props = {label: string, htmlFor: string} & React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<Props> = ({label, htmlFor,...props}: Props) => {
    return (
        <>
            <label css={styles.label} htmlFor={htmlFor}>
                {label}
            </label>
            <input
                css={styles.input}
                type={props.type}
                id={props.id}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                {...props}
            />
        </>
    )
};

export default Input;