import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { autoLogin } from "../api";
import { useAuth } from "../state/context";
import { ROUTES } from "../../../global/routing/routes";
import LoadingSpinner from "../../../components/Loader/Loader";
import { css } from "@emotion/react";
import { theme } from "../../../global/styling/theming";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const styling = {
  container: css`
    margin-top: 15%;
    flex-direction: column;
    padding: 20px;
    display: flex;
  `,
  button: css`
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background-color: ${theme.colors.ctaBtn};
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #2c3e50;
    }
  `,
};

const MagicLogin: React.FC = () => {
  const [message, setMessage] = React.useState<string | null>(null);
  const [failed, setFailed] = React.useState<boolean>(false);
  let query = useQuery();
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const token = query.get("token");

  console.log("query ", token);

  const handleAutoLogin = async (token: string) => {
    try {
      const response = await autoLogin({ token });
      const user = response.data?.user;
      if (user) {
        setUser(user);
        navigate("/" + ROUTES.PHOTO);
      } else {
        setMessage("Could not log you in, request another magic link.");
        setFailed(true);
      }
    } catch (e) {
      setMessage("Could not log you in, request another magic link.");
      setFailed(true);
    }
  };

  useEffect(() => {
    if (token) {
      handleAutoLogin(token);
    }
  }, [token]);

  return (
    <div css={styling.container}>
      <LoadingSpinner text={message ? message : "Logging you in"} />
      {failed && <button css={styling.button} onClick={() => {}}>Request magic link</button>}
    </div>
  );
};

export default MagicLogin;
