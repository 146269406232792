import config from "../../../config";
import { getLoggedInHeaders } from "../../../global/api/utils";

export const createEstimate = async ({image, description, token,}: {image: string, description: string, token: string}) => {
  const response = await fetch(`${config.api}/api/v1/estimate`, {
    method: "POST",
    body: JSON.stringify({image: image, description}),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to create estimate");
  }

  return response.json();
};
