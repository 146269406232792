import React, { useState } from "react";
import styles from "../../CheckInFailedFeedback.css";
import { useNavigate } from "react-router-dom";
import { useCheckInFeedback } from "../../../state/context";
import { useAuth } from "../../../../Auth/state/context";
import { followUpCheckIn } from "../../../api";
import { ROUTES } from "../../../../../global/routing/routes";
import TextArea from "../../../../../components/form/TextArea";

type Props = {
  goToNext: () => void;
};

const FailedFeedbackStepOne = ({ goToNext }: Props) => {
  const { response, isSuccess, date, id } = useCheckInFeedback();
  const { token } = useAuth();
  const [failureReason, setFailureReason] = useState("");
  const [sendingResponse, setSendingResponse] = useState(false);
  const [sentResponse, setSentResponse] = useState(false);
  const [failureFeedback, setFailureFeedback] = useState("");
  const navigate = useNavigate();

  const handleResponseChange = (value: string) => {
    setFailureReason(value);
  };

  const giveFailedFeedback = async (token: string, id: string) => {
    try {
      setSendingResponse(true);
      const followUp = await followUpCheckIn({
        token,
        id,
        answer: failureReason,
        question: "Why did you fail ?",
      });
      setFailureFeedback(followUp.completions);
      setSendingResponse(false);
      setSentResponse(true)
      goToNext();
    } catch (err: any) {
      setSendingResponse(false);
      // console.error("Failed to follow up:", err, err.message);
    }
  };

  const handleSubmit = () => {
    if (token && id) {
      giveFailedFeedback(token, id);
    }
  };

  const failed = !isSuccess;
  const failureFeedbackSet = failureFeedback && failureFeedback.length > 0;
  return (
    <div css={styles.feedbackContainer}>
      {failureFeedbackSet && (
        <div style={{ whiteSpace: "pre-line" }}>{failureFeedback}</div>
      )}
      {!failureFeedbackSet && failed && (
        <>
          <p css={styles.summary}>Why do you think you failed ?</p>
          <TextArea value={failureReason} onChange={handleResponseChange} />
        </>
      )}
      <button
        css={styles.button}
        disabled={failureReason.length === 0 || sendingResponse}
        onClick={() => {
          if (sentResponse) {
            goToNext();
          } else {
            handleSubmit();
          }
        }}
      >
        {sendingResponse
          ? "Laster"
          : failureFeedbackSet || sentResponse
          ? "Ok"
          : "Submit answer"}
      </button>
    </div>
  );
};

export default FailedFeedbackStepOne;
