import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import GoalForm from "./domains/Goals/GoalForm/GoalForm";
import CongratulationsScreen from "./domains/Goals/GoalCreated/GoalCreated";
import CheckIn from "./domains/CheckIns/SingleCheckIn/SingleCheckIn";
import Feedback from "./domains/CheckIns/CheckInFeedback/CheckInFeedback";
import CheckIns from "./domains/CheckIns/CheckIns/CheckIns";
import LoginPage from "./domains/Auth/page/LoginPage";
import SignUpPage from "./domains/Auth/page/SignUpPage";
import { ROUTES } from "./global/routing/routes";
import Navigation from "./components/Nav";
import SignOut from "./domains/Auth/page/SignOut";
import CheckInFailedFeedback from "./domains/CheckIns/CheckInFailedFeedback/CheckInFailedFeedback";
import EditJourney from "./domains/Goals/page/EditJourneyPage";
import LandingPage from "./domains/Marketing/pages/LandingPages/Coaching/LandingPage";
import ThankYouPage from "./domains/Marketing/pages/ThankYouPage";
import InvitationPage from "./domains/Marketing/pages/InvitationPage/InvitationPage";
import InviteUser from "./domains/Marketing/pages/InviteUser/InviteUser";
import PrivacyPolicy from "./domains/Marketing/pages/PrivacyPolicy";
import PhotoCapture from "./domains/CalorieTracker/Photo";
import PhotoEstimatorLandingPage from "./domains/Marketing/pages/LandingPages/PhotoEstimator/LandingPage";
import MagicLogin from "./domains/Auth/page/MagicLogin";
import DayOverview from "./domains/Meals/Page/DayOverview";

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
      <Navigation />
      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Calorie photo tracking */}
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.SIGNUP} element={<SignUpPage />} />
        <Route path={ROUTES.SIGNOUT} element={<SignOut />} />
        <Route path={ROUTES.AUTO_LOGIN} element={<MagicLogin />} />
        <Route path={ROUTES.PHOTO} element={<PhotoCapture />} />
        <Route path={ROUTES.OVERVIEW} element={<DayOverview />} />
        <Route index element={<PhotoEstimatorLandingPage />} />
        <Route path={ROUTES.THANK_YOU} element={<ThankYouPage />} />
        <Route path={ROUTES.INVITATION} element={<InvitationPage />} />
        <Route path={ROUTES.INVITE} element={<InviteUser />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />

        {/* Checkins */}
        <Route path={ROUTES.CHECKINS} element={<CheckIns />} />
        <Route path={ROUTES.GOAL_CREATE} element={<GoalForm />} />
        <Route
          path={ROUTES.GOAL_CREATED}
          element={<CongratulationsScreen coachName="Maria" />}
        />
        <Route path={ROUTES.CHECKIN} element={<CheckIn />} />
        <Route path={ROUTES.FEEDBACK} element={<Feedback />} />
        <Route
          path={ROUTES.FAILED_FEEDBACK}
          element={<CheckInFailedFeedback />}
        />
        <Route path={ROUTES.CHECKINS} element={<CheckIns />} />
        <Route path={ROUTES.JOURNEY} element={<EditJourney />} />

        {/* Marketing */}
        <Route path={ROUTES.COACHING_LANDING} element={<LandingPage />} />
        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
      </Route>
    </Routes>
  );
};

export default Routing;
