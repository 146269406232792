import { css } from "@emotion/react";

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  `,
  title: css`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  `,
  date: css`
    font-size: 16px;
    margin-bottom: 10px;
  `,
  status: css`
    font-size: 16px;
    margin-bottom: 20px;
  `,
  summary: css`
    font-size: 16px;
    margin-bottom: 20px;
  `,
  button: css`
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  `,
};

export default styles;
