import { css } from "@emotion/react";
import { theme } from "../../../global/styling/theming";

export const styling = {
  container: css`
    width: 100%;
    margin: auto;
  `,
  preview: css`
    width: 100%;
    margin: auto:
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  loadingCover: css`
    background: rgb(48 48 48 / 75%);
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  loadingCoverText: css`
    font-family: 'Inter';
    font-size: 48px;
  `,
  button: css`
    padding: 8px 16px;
    background-color: #a5ecd7;
    color: #333;
    font-family: "Inter";
    font-weight: bold;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin: auto;
    display: flex;
    margin-top: 20px;
  `,
  cameraBtn: css`
    background: #333;
    border: 10px solid #a5ecd7;
    border-radius: 64px;
    width: 30px;
    height: 30px;
    padding: 40px;
    margin: auto;
    cursor: pointer;
    display: flex;
    margin-top: 20px;
  `,
  xMarkButton: css`
    border: none;
    color: white;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 30px;
    background: #333;
    border-radius: 60px;
    padding: 4px 10px;
  `,
  estimateBtn: css`
    padding: 11px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    display: block;
    font-size: 20px;
    margin-top: 0;
  `,
  buttonContainer: css`
    padding: 20px 0;
  `,
  addDescriptionContainer: css`
    display: flex;
    flex-direction: column;
    color: white;
    padding: 20px;
    padding-bottom: 0;
    font-family: "Inter";
  `,
  descriptionLabel: css`
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 12px;
  `,
  descriptionTextArea: css`
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    font-family: "Inter";
  `,
  disabledDescription: css`
    opacity: 0.7;
  `,
};
