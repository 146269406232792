import React, { useState } from "react";
import { css } from "@emotion/react";
import { theme } from "../../../global/styling/theming";
import config from "../../../config";
import { ROUTES } from "../../../global/routing/routes";

interface LoginFormProps {
  onSubmit: (formData: LoginFormData) => void;
}

export interface LoginFormData {
  email: string;
  password: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formData);
  };

  const chargeNow = config.FEATURES.CHARGE_NOW;

  return (
    <div css={styles.container}>
      <h1 css={styles.title}>Login</h1>
      <form css={styles.form} onSubmit={handleSubmit}>
        <input
          css={styles.input}
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          css={styles.input}
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          required
        />
        <button css={styles.button} type="submit">
          Login
        </button>
      </form>
      <a
        href={ROUTES.SIGNUP}
        target={"_self"}
        style={{ marginTop: "20px" }}
      >
        Sign up
      </a>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-family: "Comfortaa";
    margin-top: 20px;
  `,
  form: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.mainDarkColor};
    padding: 30px;
    border-radius: 5px;
  `,
  title: css`
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
  `,
  input: css`
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  `,
  button: css`
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background-color: ${theme.colors.ctaBtn};
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #2c3e50;
    }
  `,
};

export default LoginForm;
