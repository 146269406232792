export const theme = {
    fontFamily: {
      main: "Inter",
      secondary: "Comfortaa",
      gill: "Gill Sans"
    },
    colors: {
      mainDarkColor: "#333333",
      lightGreyTextColor: "#bfbfbf",
      darkTextColor: "#17191e",
      ctaBtn: "#0daa4c",
      logoGreen: "#A5ECD7",
      status: {
        success: "#34B971",
        failed: "#89302A",
      },
    },
  };
  