import React from 'react';
import LoginContainer from '../containers/LoginContainer';


const LoginPage = () => {
    
    return (
        <>
            <LoginContainer />
        </>
    )
};

export default LoginPage;