import React from "react";
import { css, keyframes } from "@emotion/react";

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = ({ text }: { text: string }) => (
  <div css={containerStyles}>
    <div css={spinnerStyles} />
    <p css={textStyles}>{text}</p>
  </div>
);

// Emotion CSS styles
const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const spinnerStyles = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #61dafb;
  animation: ${spinAnimation} 1s linear infinite;
`;

const textStyles = css`
  margin-top: 1rem;
  font-size: 1.2rem;
`;

export default LoadingSpinner;
