import React from "react";
import { Star } from "../../../global/icons";
import { format } from "date-fns";
import styles from "./SuccessfulFeedback.css";
import FeedbackHeader from "../components/FeedbackHeader/FeedbackHeader";

type Props = {
  endDate: string;
  response: string;
  goToFeedbackPage: () => void;
};

const SuccesfulFeedback: React.FC<Props> = ({
  endDate,
  response,
  goToFeedbackPage,
}) => {
  return (
    <div>
      <FeedbackHeader isSuccess={true} date={format(new Date(endDate), "dd MMM yy")} />
      <div css={styles.success}>
        <Star />
      </div>
      <div css={styles.response}>{response}</div>
      <div css={styles.responseButtonContainer}>
        <button
          css={styles.button}
          onClick={() => {
            goToFeedbackPage();
          }}
        >
          OK!
        </button>
      </div>
    </div>
  );
};

export default SuccesfulFeedback;