import React, { useState } from 'react';
import { inviteUser } from '../../api';
import { useAuth } from '../../../Auth/state/context';

const InviteUser: React.FC = () => {
    const { token } = useAuth();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [response, setResponse] = useState('');

    const clearValues = () => {
        setName('');
        setPhoneNumber('');
        setResponse('User invited successfully');
    };

    const clearResponse = () => {
        setResponse('');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Send API call to me/invite route with name and phoneNumber
        // Replace the API_URL with your actual API endpoint
        const invitation = { name, phoneNumber };
        if(token)
        inviteUser({ invitation, token })
        clearValues();
    };

    return (
        <>
        {response.length > 0 && <div>
            <p>{response}</p>
            <button onClick={clearResponse}>Invite Another</button>    
        </div>}
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </label>
            <br />
            <label>
                Phone Number:
                <input
                    type="text"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                />
            </label>
            <br />
            <button type="submit">Invite</button>
        </form>
        </>
    );
};

export default InviteUser;