import React from "react";
import { CALORIE_ESTIMATE_RESULTS } from "./types";
import { css } from "@emotion/react";
import { TrashIcon } from "./Delete";
import { PlusIcon, RefreshIcon } from "../../components/icons/Icons";
import { useMeals } from "../Meals/state/context";
import { useAuth } from "../Auth/state/context";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../global/routing/routes";

interface ResultEstimateProps {
  estimate: CALORIE_ESTIMATE_RESULTS;
  newPhoto: () => void;
  deleteItem: (itemName: string) => void;
  estimateAgain: () => void;
  estimating: boolean;
  uploadedImageUrl?: string;
}

const styling = {
  counts: css`
    display: flex;
    padding: 0 40px;
    justify-content: center;
  `,
  count: css`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  `,
  CountCircle: css`
    border: 4px solid white;
    padding: 13px 10.5px;
    border-radius: 60px;
    color: white;
    font-size: 14px;
    font-family: "Inter";
    min-width: 24px;
    text-align: center;
  `,
  countUnit: css`
    color: white;
    margin-top: 10px;
    text-align: center;
  `,
  kcals: css`
    border-color: #fff;
  `,
  protein: css`
    border-color: #a5ecd7;
  `,
  carbs: css`
    border-color: #bceca5;
  `,
  fat: css`
    border-color: #549683;
  `,
  proteinText: css`
    border-color: #a5ecd7;
  `,
  carbsText: css`
    border-color: #bceca5;
  `,
  fatText: css`
    border-color: #549683;
  `,
  item: css`
    background: none;
    border: none;
    width: 100%;

    display: flex;
    color: white;
    justify-content: space-between;
    border-bottom: 1px solid #ffffff4a;
    padding-bottom: 4px;
    margin-top: 10px;
  `,
  itemTitle: css`
    margin: 0;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 700;
    text-transform: capitalize;
    text-align: left;
  `,
  itemSize: css`
    margin: 0;
    font-size: 12px;
    font-family: "Inter";
    font-weight: 100;
    text-align: left;
  `,
  itemCalorieContainer: css`
    font-size: 20px;
    font-family: "Inter";
  `,
  itemCalorieValue: css`
    margin-right: 4px;
  `,
  itemCalorieUnit: css`
    color: #ffffff6e;
    font-weight: 300;
  `,
  header: css`
    font-family: "Inter";
    color: white;
  `,
  buttonsContainer: css`
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
  `,
  // Copy from Photo.tsx
  button: css`
    padding: 8px 16px;
    background-color: #a5ecd7;
    color: #333;
    font-family: "Inter";
    font-weight: bold;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    margin-top: 20px;
  `,
  leftSideItem: css`
    display: flex;
    align-items: center;
  `,
  deleteItem: css`
    margin: 10px;
    margin-left: 20px;
  `
};

const ResultEstimate: React.FC<ResultEstimateProps> = (props) => {
  const { saveMeal, meals, isSaving } = useMeals();
  const { token, id } = useAuth();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);
  const deleteItem = () => {
    if(selectedItem)
    props.deleteItem(selectedItem);
  }
  const { estimate } = props;

  const triggerSaveMeal = async () => {
    const meal = {
      title: estimate.items.find((v,i) => i === 0)?.name || 'Unknown',
      items: estimate.items,
      total: estimate.total,
      imageUrl: props.uploadedImageUrl,
      date: new Date().toISOString(),
      userId: id || 'Unknown',
    };
    console.log("triggerSaveMeal ", meal);
    if(token)
    await saveMeal(meal, token);
    navigate("/"+ROUTES.OVERVIEW);
  };

  return (
    <div
      style={{
        padding: "20px",
        paddingTop: 0,
      }}
    >
      <div>
        {/** Total */}
        <h3 css={styling.header} style={{ marginTop: 0 }}>
          Total
        </h3>
        <div css={styling.counts}>
          <div css={styling.count}>
            <span css={[styling.CountCircle, styling.kcals]}>
              {estimate.total.calories}
            </span>
            <span css={styling.countUnit}>Calories</span>
          </div>
          <div css={styling.count}>
            <span css={[styling.CountCircle, styling.protein]}>
              {estimate.total.protein}
            </span>
            <span css={[styling.countUnit, styling.proteinText]}>Protein</span>
          </div>
          <div css={styling.count}>
            <span css={[styling.CountCircle, styling.carbs]}>
              {estimate.total.carbs}
            </span>
            <span css={[styling.countUnit, styling.carbsText]}>Carbs</span>
          </div>
          <div css={styling.count}>
            <span css={[styling.CountCircle, styling.fat]}>
              {estimate.total.fat}
            </span>
            <span css={[styling.countUnit, styling.fatText]}>Fat</span>
          </div>
        </div>
        {/** Items */}
        <h3 css={styling.header}>Items</h3>
        <div>
          {estimate.items.map((item, index) => {
            const isSelected = selectedItem === item.name;
            return (
              <button
                key={item.name}
                css={styling.item}
                onClick={() => {
                  if (isSelected) {
                    setSelectedItem(null);
                  } else {
                    setSelectedItem(item.name);
                  }
                }}
              >
                <div css={styling.leftSideItem}>
                    <div>
                    <h4 css={styling.itemTitle}>{item.name}</h4>
                    <p css={styling.itemSize}>Serving, {item.estimated_size} g</p>
                    </div>
                    {isSelected && <div onClick={() => {
                        deleteItem();
                    }} css={styling.deleteItem}>{TrashIcon}
                        </div>}
                </div>
                <div>
                  <span css={styling.itemCalorieContainer}>
                    <span css={styling.itemCalorieValue}>{item.calories}</span>
                    <span css={styling.itemCalorieUnit}>kcal</span>
                  </span>
                </div>
              </button>
            );
          })}
        </div>
        {/* Add item */}
        <div
          css={styling.buttonsContainer}
          style={{
            display: "flex",
            marginTop: 20,
          }}
        >
          <button css={styling.button}
            disabled={isSaving}
            onClick={() => {
              triggerSaveMeal();
            }}
          >
            {isSaving ? "Saving" : "Save meal"}
          </button>
          <div style={{display: "flex"}}>            
            <button css={styling.button}
              style={{
                marginRight: 10
              }}
              disabled={props.estimating}
              onClick={() => {
                  props.estimateAgain();
              }}
            >
              {props.estimating &&
                "Estimating"
              }
              {!props.estimating &&
                RefreshIcon
              }
            </button>
            <button
              css={styling.button}
              onClick={() => {
                props.newPhoto();
              }}
            >
              {PlusIcon}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultEstimate;
