import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import isMobile from "is-mobile";
import { createEstimate } from "./api";
import { useAuth } from "../Auth/state/context";
import { styling } from "./Photo/Photo.css";
import { CALORIE_ESTIMATE_RESULTS } from "./types";
import { wait } from "../../global/utils";
import ResultEstimate from "./ResultEstimate";
import RequireAuthentication from "../Auth/hooks/RequireAuthentication";
import RequireConfirmation from "../Auth/hooks/RequireConfirmation";
import { CrossIcon } from "./Delete";
import LoadingSpinner from "../../components/Loader/Loader";

const PhotoCapture: React.FC = () => {
  const webcamRef = useRef<Webcam>(null);
  const { token } = useAuth();
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [estimating, setIsEstimating] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [estimateResult, setEstimateResult] =
    useState<CALORIE_ESTIMATE_RESULTS | null>(null);
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (uploadedImageUrl && estimateResult === null) {
      console.log("About to trigger estimate");
      triggerEstimate(uploadedImageUrl);
    }
  }, [uploadedImageUrl]);

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setImageSrc(imageSrc);
    }
  };

  const triggerEstimate = async (imageUrl: string) => {
    console.log("Triggering estimate");
    const estimate = await createEstimate({
      image: imageUrl,
      description,
      token: token || "",
    });
    console.log("estimate ", estimate);
    const parsedEstimate = JSON.parse(estimate);
    setEstimateResult(parsedEstimate);
    setIsEstimating(false);
  };

  const uploadImage = async () => {
    console.log("uploadImage called ", imageSrc);
    if (!imageSrc) return;

    setUploading(true);

    const dataUrlToBlob = (dataUrl: string): Blob => {
      const [metadata, base64] = dataUrl.split(",");
      const byteString = atob(base64);
      const mimeType = metadata.split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      return new Blob([arrayBuffer], { type: mimeType });
    };

    const imageBlob = dataUrlToBlob(imageSrc);

    const formData = new FormData();
    formData.append("file", imageBlob);
    formData.append("upload_preset", "fitmentor_photo");
    formData.append("cloud_name", "handiton");

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/handiton/upload", // Replace with your cloud name
        formData
      );
      setUploadedImageUrl(response.data.secure_url);
    } catch (error) {
      console.error("Error uploading the image", error);
    } finally {
      setUploading(false);
    }
  };

  const estimate = async () => {
    console.log("Capturing photo about to be called ",imageSrc);
    capture();
    await wait(2000);
    console.log("Capturing photo should be done ",imageSrc);
    console.log("estimate process started");
    setIsEstimating(true);
    try {
      await uploadImage();
    } catch (error) {
      console.error("Error uploading the image", error);
      setIsEstimating(false);
    }
  };

  const deleteItemFromEstimate = (itemName: string) => {
    console.log("Delete item ", itemName);
    const newEstimateItems = estimateResult?.items.filter((i) => i.name !== itemName);
    console.log("newEstimateItems ",newEstimateItems);
    /*
    // @ts-ignore
    const newTotal = newEstimateItems?.reduce((acc, item) => {
      return {
        calories: acc.calories + item.calories,
        protein: acc.protein + item.protein,
        carbs: acc.carbs + item.carbs,
        fat: acc.fat + item.fat,
      };
    });
    console.log("newTotal ", newTotal);
    */
    console.log("existing estimateResult ",estimateResult);
  };

  const retakePhoto = () => {
    setEstimateResult(null);
    setImageSrc(null);
  }

  const uploadingOrEstimating = uploading || estimating;
  console.log("estimating ",estimating);
  return (
    <div
      style={{
        background: "#2D2A2A",
        minHeight: "calc(100vh - 74px)",
        zIndex: 98
      }}
    >
      <RequireAuthentication />
      {/*<RequireConfirmation />*/}
      <div
        style={{
          maxWidth: "768px",
          margin: "auto"
        }}
      >
      {!imageSrc ? (
        <div css={styling.container}>
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={{
              facingMode: isMobile()
                ? {
                    exact: "environment",
                  }
                : "user",
            }}
            screenshotFormat="image/jpeg"
            style={{ width: "100%" /* , minHeight: "580px" */ }}
          />
          <button css={[styling.cameraBtn]} onClick={capture} />
        </div>
      ) : (
        <div css={styling.preview}>
          <img src={imageSrc} alt="Captured" style={{ width: "100%" }} />
          <button css={styling.xMarkButton} onClick={() => retakePhoto()}>{CrossIcon}</button>
          {uploadingOrEstimating &&      
            <div className="loadingCover" css={styling.loadingCover}>
              {/*<span css={styling.loadingCoverText}>{uploading ? "Uploading..." : "Estimating..."}</span>*/}
              <LoadingSpinner text={uploading ? "Uploading..." : "Estimating..."} />
            </div>
          }
        </div>
      )}
      {imageSrc && !estimateResult &&
        <div css={styling.addDescriptionContainer}>
          {/*<label css={styling.descriptionLabel}>Add description</label>*/}
          <textarea css={[styling.descriptionTextArea, uploadingOrEstimating ? styling.disabledDescription : {}]}
              disabled={uploadingOrEstimating}
          onChange={(event) => {
            setDescription(event.target.value);
          }} placeholder="Describe the meal"></textarea>
        </div>
      }
      <div css={styling.buttonContainer}>
        {imageSrc && !estimateResult && (
          <div
            style={{
              display: "flex",
            }}
          >
            <button
              css={[styling.button, styling.estimateBtn]}
              onClick={() => {
                if(!uploadingOrEstimating) {
                  estimate();
                }
              }}
              disabled={uploadingOrEstimating}
            >
              {uploadingOrEstimating
                ? uploading ? "Uploading..." : "Estimating..."
                : "Estimate calories"}
            </button>
            {/*            
              <button css={styling.button} onClick={() => retakePhoto()}>
                Retake Photo
              </button>
            */}
          </div>
        )}
        {estimateResult && 
          <ResultEstimate estimate={estimateResult} newPhoto={retakePhoto} 
            uploadedImageUrl={uploadedImageUrl || ""}
            deleteItem={(itemName: string) => {
              deleteItemFromEstimate(itemName);
            }}
            estimateAgain={() => {
              triggerEstimate(uploadedImageUrl || "");
            }}
            estimating={estimating}
          />
        }
      </div>
      </div>
    </div>
  );
};

export default PhotoCapture;
