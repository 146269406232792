import config from "../../../config";
import { getLoggedInHeaders } from "../../../global/api/utils";

type InvitationPayload = {
    name: string;
    phoneNumber: string;
}

export const inviteUser = async ({invitation, token}: {invitation: InvitationPayload, token: string}) => {
  const response = await fetch(`${config.api}/api/v1/users/me/invite`, {
    method: "POST",
    body: JSON.stringify(invitation),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to create invite");
  }

  return response.json();
};
