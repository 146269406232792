import React from "react";
import { styles } from "./Filters.css";
import { format } from "date-fns";

type Props = {
  monthAndYears: string[];
  setMonth: (month: string) => void;
};

const Filters: React.FC<Props> = ({ monthAndYears, setMonth }) => {
  return (
    <div css={styles.filters}>
      <p css={styles.filterLabel}>By month</p>
      <select
        css={styles.selectFilter}
        onChange={(event) => {
          setMonth(event.currentTarget.value);
        }}
      >
        <option value="All">All</option>
        {monthAndYears.map((monthAndYear) => (
          <option key={monthAndYear} value={monthAndYear}>
            {format(new Date(monthAndYear), "MMMM yy")}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Filters;
