import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Input from "../../../components/form/Input";
import GoalForm from "../GoalForm/GoalForm";
import { useAuth } from "../../Auth/state/context";
import { Journey } from "../../Auth/api/types";
import { findUserJourney } from "../../CheckIns/api";

const EditJourney = () => {
    const [journey, setJourney] = useState<Journey | null>(null);

    const { id } = useParams();
    const { token, userJourney } = useAuth();

    const findJourney = async (token: string) => {
        try {
          const journey = await findUserJourney(token);
          if(journey)  {
            setJourney(journey)
            return 
          }
        } catch(error: any) {
          console.log('error - journey ', error);
        }
    }

    useEffect(() => {
        if(!userJourney && !!token) {
          findJourney(token);
        }
      },[userJourney])

    return (
        <div>
            <GoalForm
                initialValues={{
                    calorieIntake: journey?.calorieIntake.toString() || "",
                    // @ts-ignore
                    end: journey?.end || "",
                    weight: journey?.goal.weight.toString() || "",
                    why: journey?.why || "",
                }}
                existingId={id}
            />
        </div>
    )
};

export default EditJourney;