import React, { useEffect, useState } from "react";
import styles from "./SingleCheckIn.css";
import { useMutation } from "@tanstack/react-query";
import { createCheckIn, findUserJourney } from "../api";
import { CheckIn, CheckInPayload } from "../types";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../global/routing/routes";
import { useAuth } from "../../Auth/state/context";
import { useCheckInFeedback } from "../state/context";
import StatusMessage from "../../../components/StatusMessage";
import { Journey } from "../../Auth/api/types";
import FeedbackContainer from "../components/FeedbackContainer";
import SuccesfulFeedback from "../SuccesfulFeedback/SuccesfulFeedback";

const SingleCheckIn = () => {
  const initialDate = new Date().toISOString().slice(0, 10);
  const [calorieIntake, setCalorieIntake] = useState("");
  const [endDate, setEndDate] = useState(initialDate);
  const [weight, setWeight] = useState("");
  const [error, setError] = useState("");
  const [response, setResponse] = useState<string | null>(null);

  const { token } = useAuth();
  const { setFeedback, isSuccess } = useCheckInFeedback();
  const navigate = useNavigate();

  const mutation = useMutation(createCheckIn);

  const sendData = async (checkIn: CheckInPayload, token: string) => {
    try {
      const response = await mutation.mutateAsync({ checkIn, token });
      setFeedback({
        response: response.response,
        id: response.id,
        success: response.isSuccess,
        date: endDate,
      });
      if (response.isSuccess) {
        setResponse(response.response);
      } else {
        goToFailedFeedback();
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if(calorieIntake === "" || endDate === "" || weight === "") {
      return setError("Please fill in all fields")
    }

    const checkIn: CheckInPayload = {
      calorieIntake: parseInt(calorieIntake),
      date: new Date(endDate),
      weight: parseFloat(weight),
    };
    if (token) {
      sendData(checkIn, token);
    } else {
      // set error
    }
  };

  const goToFailedFeedback = () => {
    navigate(`/${ROUTES.FAILED_FEEDBACK}`);
  };

  const goToFeedbackPage = () => {
    if (isSuccess) {
      navigate(`/${ROUTES.CHECKINS}`);
    } else {
      goToFailedFeedback();
    }
  };

  const showingSuccess = !!response
  return (
    <FeedbackContainer>
      <h1 css={styles.title}>{showingSuccess ? "Feedback" : "CheckIn"}</h1>
      <StatusMessage message={error} error={!!error} />
      {response && (
        <SuccesfulFeedback
          endDate={endDate}
          response={response}
          goToFeedbackPage={goToFeedbackPage}
        />
      )}
      {!response && (
        <form css={styles.form} onSubmit={handleSubmit}>
          <label css={styles.label}>Calorie Intake</label>
          <input
            css={styles.input}
            type="number"
            placeholder="2500 kcal"
            value={calorieIntake}
            onChange={(e) => setCalorieIntake(e.target.value)}
          />

          <label css={styles.label}>End Date</label>
          <input
            css={styles.input}
            type="date"
            placeholder="12/05/2023"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />

          <label css={styles.label}>Weight</label>
          <input
            css={styles.input}
            type="number"
            placeholder="in kg"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />

          <button
            css={styles.button}
            type="submit"
            disabled={mutation.isLoading ? true : false}
          >
            {mutation.isLoading ? "Loading" : "Add"}
          </button>
        </form>
      )}
    </FeedbackContainer>
  );
};

export default SingleCheckIn;
