import config from "../../../config";
import { getLoggedInHeaders } from "../../../global/api/utils";
import { CheckInPayload } from "../types";

export const createCheckIn = async ({checkIn, token}: {checkIn: CheckInPayload, token: string}) => {
  const response = await fetch(`${config.api}/api/v1/checkins`, {
    method: "POST",
    body: JSON.stringify(checkIn),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to create checkin");
  }

  return response.json();
};

export const findCheckIns = async (token: string) => {
  try {
    const url = `${config.api}/api/v1/checkins/`;
    const resulting = await fetch(url, { headers: getLoggedInHeaders(token) });
    const result = await resulting.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCheckIn = async (token: string, id: string) => {
  try {
    const url = `${config.api}/api/v1/checkins/${id}`;
    const resulting = await fetch(url, { method: "DELETE",headers: getLoggedInHeaders(token) });
    const result = await resulting.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const findUserJourney = async (token: string) => {
  try {
    const url = `${config.api}/api/v1/journeys/byUser`;
    const resulting = await fetch(url, { headers: getLoggedInHeaders(token) });
    if(resulting.status === 200) {
      const result = await resulting.json();
      console.log("result ",result);
      return result;
    }
    return null
  } catch (error) {
    console.log(error);
    return null
  }
};


export const followUpCheckIn = async ({id, token, answer, question,getCoachFeedback}: {id: string, token: string, answer: string, question: string, getCoachFeedback?: boolean}) => {
  const response = await fetch(`${config.api}/api/v1/checkins/follow-up`, {
    method: "POST",
    body: JSON.stringify({id, answer, question, getCoachFeedback}),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to create checkin");
  }

  return response.json();
};


export const fetchCheckIn = async ({id, token}: {id: string, token: string}) => {
  const response = await fetch(`${config.api}/api/v1/checkins/${id}`, {
    method: "GET",
    headers: getLoggedInHeaders(token),
  });

  console.log("response ",response);

  if (!response.ok) {
    throw new Error("Failed to create checkin");
  }

  return response.json();
};
