export enum ROUTES {
    HOME = '/',
    LOGIN = 'login',
    AUTO_LOGIN = 'autologin',
    SIGNUP = 'signup',
    SIGNOUT = 'signout',
    GOAL_CREATE = 'create-goal',
    GOAL_CREATED = 'created-goal',
    FEEDBACK = 'feedback',
    FAILED_FEEDBACK = 'failed_feedback',
    CHECKINS = 'checkins',
    CHECKIN = 'checkin',
    JOURNEY = 'journey/:id',
    WELCOME = 'welcome',
    THANK_YOU = 'thank-you',
    INVITATION = 'invitation/:name',
    INVITE = 'invite',
    PHOTO = 'photo',
    OVERVIEW = 'overview',
    PRIVACY_POLICY = 'privacy-policy',
    COACHING_LANDING = 'coaching',
}
