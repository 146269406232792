import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isSameMonth } from "date-fns";
import { tableStyles, thStyles, styles } from "./CheckIns.css";
import { deleteCheckIn, findCheckIns, findUserJourney } from "../api";
import { useAuth } from "../../Auth/state/context";
import RequireAuthentication from "../../Auth/hooks/RequireAuthentication";
import { ROUTES } from "../../../global/routing/routes";
import { Journey } from "../../Auth/api/types";
import { formatCheckIns, listMonthsAndYears } from "./utils";
import Filters from "./components/Filters/Filters";
import JourneyHeader from "./components/JourneyHeader/JourneyHeader";
import SelectedCheckIn from "./components/SelectedCheckIn/SelectedCheckIn";
import CheckInRow from "./components/CheckInRow/CheckInRow";

export type CheckIn = {
  id: string;
  date: string;
  calories: number;
  weight?: number;
  isSuccess: boolean;
};

export type CheckInRequestResponse = {
  _id: string;
  calorieIntake: number;
  weight?: number;
  date: string;
  isSuccess: boolean;
  userId: string;
};

const CheckIns = () => {
  const { token, userJourney } = useAuth();
  const navigate = useNavigate();
  const [checkIns, setCheckIns] = useState<CheckInRequestResponse[]>([]);
  const [selectedCheckIn, setSelectedCheckIn] = useState<CheckIn | null>(null);
  const [deletingCheckIn, setDeletingCheckIn] = useState<boolean>(false);
  const [journey, setJourney] = useState<Journey | null>(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState<string | null>(
    new Date().toISOString()
  );

  useEffect(() => {
    if (!userJourney && !!token) {
      findJourney(token);
    }
  }, [userJourney]);

  const findJourney = async (token: string) => {
    try {
      const journey = await findUserJourney(token);
      if (journey) {
        setJourney(journey);
        return;
      }
      navigate("/"+ROUTES.GOAL_CREATE);
    } catch (error: any) {
      if(error === "Journey not found") {
        navigate(ROUTES.GOAL_CREATE);
        return;
      }
    }
  };

  const fetchCheckins = async (token: string) => {
    try {
      const CheckInLogs = await findCheckIns(token);
      setCheckIns(CheckInLogs);
    } catch (err: any) {
      console.error("Failed to create goal:", err.message);
      // setError(err.message);
    }
  };

  const deleteCheckin = async (token: string, id: string) => {
    try {
      setDeletingCheckIn(true);
      const deleted = await deleteCheckIn(token, id);
      setDeletingCheckIn(false);
      await fetchCheckins(token);
    } catch (err: any) {
      setDeletingCheckIn(false);
      // setError(err.message);
    }
  };

  React.useEffect(() => {
    if (token) {
      fetchCheckins(token);
    }
  }, [token]);

  const monthAndYears = listMonthsAndYears(
    checkIns.map((checkIn) => checkIn.date)
  );

  const formattedCheckIns = formatCheckIns(checkIns).filter((checkIn) => {
    if (!selectedMonthYear) return true;
    return isSameMonth(new Date(checkIn.date), new Date(selectedMonthYear));
  });

  const _journey = userJourney || journey;
  return (
    <>
      <JourneyHeader journey={_journey} />
      <div css={styles.container}>
        <RequireAuthentication />
        <div></div>
        <h2 css={styles.header}>CheckIns</h2>
        <Filters
          monthAndYears={monthAndYears}
          setMonth={(month) => {
            if (month === "All") {
              setSelectedMonthYear(null)
              return;
            };
            setSelectedMonthYear(month);
          }}
        />
        <table css={tableStyles}>
          <thead>
            <tr>
              <th css={thStyles}>Date</th>
              <th css={thStyles}>Calories</th>
              <th css={thStyles}>Weight</th>
              <th css={thStyles}>Status</th>
            </tr>
          </thead>
          <tbody>
            {formattedCheckIns.map((checkIn) => (
              <CheckInRow
                key={checkIn.id}
                checkIn={checkIn}
                setSelectedCheckIn={(checkIn) => {
                  setSelectedCheckIn(checkIn);
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
      <SelectedCheckIn
        selectedCheckIn={selectedCheckIn}
        isDisabled={deletingCheckIn && !!token}
        deleteCheckIn={(id) => {
          if (token) deleteCheckin(token, id);
          setSelectedCheckIn(null);
        }}
        select={() => {
          setSelectedCheckIn(null);
        }}
      />
      <div css={styles.buttonContainer}>
        <button
          css={styles.button}
          onClick={() => {
            navigate("/" + ROUTES.CHECKIN);
          }}
        >
          Check In
        </button>
      </div>
    </>
  );
};

export default CheckIns;
