import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { theme } from "../../../../global/styling/theming";
import CtaButton from "../../components/CTAButton/CTAButton";
import { ROUTES } from "../../../../global/routing/routes";
import LandingPage from "../LandingPages/Coaching/LandingPage";

export const styles = {
  container: css`
    padding: 20px;
    text-align: center;
  `,
  header: css`
    font-family: ${theme.fontFamily.main}, sans-serif;
    font-size: 32px;
    font-weight: 500;
  `,
  ctaContainer: css`
    position: relative;
  `,
  forFreeLabel: css`
    position: absolute;
    transform: rotate(25deg);
    background: lightblue;
    padding: 4px 8px;
    border-radius: 9px;
    right: 85px;
  `,
};

const InvitationPage: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();

  const formattedName = name
    ? name.charAt(0).toUpperCase() + name.slice(1)
    : "Anonymous";

  const goToSignUpPage = () => {
    navigate("/"+ROUTES.SIGNUP + "?invitedBy=" + name);
  };

  return (
    <>
      <div css={styles.container}>
        <h1 css={styles.header}>Invitation to FitMentor</h1>
        <h2>{formattedName}</h2>
        <p>has invited you to try out FitMentor</p>
        <div css={styles.ctaContainer}>
          <CtaButton onClick={goToSignUpPage} text="Join today" />
          <span css={styles.forFreeLabel}>For free!</span>
        </div>
      </div>
      <LandingPage />
    </>
  );
};

export default InvitationPage;
