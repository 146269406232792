import React from "react";
import { css } from "@emotion/react";
import { CALORIE_ESTIMATE_RESULTS } from "../../domains/CalorieTracker/types";

const styling = {
  counts: css`
    display: flex;
    padding: 0 40px;
    justify-content: center;
  `,
  count: css`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  `,
  CountCircle: css`
    border: 4px solid white;
    padding: 13px 10.5px;
    border-radius: 60px;
    color: white;
    font-size: 14px;
    font-family: "Inter";
    min-width: 24px;
    text-align: center;
  `,
  countUnit: css`
    color: white;
    margin-top: 10px;
    text-align: center;
  `,
  kcals: css`
    border-color: #fff;
  `,
  protein: css`
    border-color: #a5ecd7;
  `,
  carbs: css`
    border-color: #bceca5;
  `,
  fat: css`
    border-color: #549683;
  `,
  proteinText: css`
    border-color: #a5ecd7;
  `,
  carbsText: css`
    border-color: #bceca5;
  `,
  fatText: css`
    border-color: #549683;
  `,
};

type Props = {
    estimate: CALORIE_ESTIMATE_RESULTS;
}

const MacroCircles = ({estimate}: Props) => {
  return (
    <div css={styling.counts}>
      <div css={styling.count}>
        <span css={[styling.CountCircle, styling.kcals]}>
          {estimate.total.calories}
        </span>
        <span css={styling.countUnit}>Calories</span>
      </div>
      <div css={styling.count}>
        <span css={[styling.CountCircle, styling.protein]}>
          {estimate.total.protein}
        </span>
        <span css={[styling.countUnit, styling.proteinText]}>Protein</span>
      </div>
      <div css={styling.count}>
        <span css={[styling.CountCircle, styling.carbs]}>
          {estimate.total.carbs}
        </span>
        <span css={[styling.countUnit, styling.carbsText]}>Carbs</span>
      </div>
      <div css={styling.count}>
        <span css={[styling.CountCircle, styling.fat]}>
          {estimate.total.fat}
        </span>
        <span css={[styling.countUnit, styling.fatText]}>Fat</span>
      </div>
    </div>
  );
};

export default MacroCircles;
