import React, { useState } from "react";
import { css } from "@emotion/react";
import { theme } from "../../../global/styling/theming";

interface SignupFormProps {
  onSubmit: (formData: SignUpFormData) => void;
}

export interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  referralCode?: string;
}

const SignupForm: React.FC<SignupFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState<SignUpFormData>({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    referralCode: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <div css={styles.container}>
      <h1 css={styles.title}>Join <span css={styles.titleEmphasis}>today</span>. <br/> 1 month free.</h1>
      <form css={styles.form} onSubmit={handleSubmit}>
        <input
          css={styles.input}
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          css={styles.input}
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          css={styles.input}
          type="text"
          name="referralCode"
          placeholder="Referral code"
          value={formData.referralCode}
          onChange={handleInputChange}
        />
        {/*
          <input
            css={styles.input}
            type="text"
            name="phoneNumber"
            placeholder="Phone number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />
        */}
        <input
          css={styles.input}
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          required
        />
        <button css={styles.button} type="submit">
          Sign Up
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    font-family: "Comfortaa";
    padding: 20px;
    margin-top: 0;
    @media (min-width: 550px) {
      align-items: center;
      justify-content: center;
    }
  `,
  form: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.mainDarkColor};
    padding: 30px;
    border-radius: 5px;
    @media (min-width: 550px) {
      min-width: 375px;
    }
    min-width: 25%;
    
  `,
  title: css`
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    font-weight: 200;
  `,
  titleEmphasis: css`
    text-decoration: underline;
    font-weight: 600;
  `,
  input: css`
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  `,
  button: css`
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background-color: ${theme.colors.ctaBtn};
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #2c3e50;
    }
  `,
};

export default SignupForm;
