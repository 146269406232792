import axios from "axios";
import { SignUpFormData } from "../components/SignUp";
import { LoginFormData } from "../components/Login";
import { AutoLoginResponse, LoginResponse } from "./types";
import { RESPONSE_STATUSES } from "../../../global/api/types";
import config from "../../../config";
import { getLoggedInHeaders } from "../../../global/api/utils";

export const registerUser = async ({
  name,
  email,
  password,
  phoneNumber,
  referralCode,
}: SignUpFormData) => {
  // const username = `${firstName} ${lastName}`.toLowerCase();
  try {
    const response = await axios.post(`${config.api}/api/v1/users/register`, {
      username: name,
      email,
      password,
      phoneNumber,
      referralCode,
    });

    return {
      data: response.data,
      status: RESPONSE_STATUSES.SUCCESS,
    };
  } catch (error: any) {
    return {
      data: null,
      status: RESPONSE_STATUSES.FAILED,
    };
  }
};

export const loginUser = async ({ email, password }: LoginFormData) => {
  try {
    const loginUrl = `${config.api}/api/v1/users/signin`;
    const response = await axios.post<LoginResponse>(
      loginUrl,
      {
        email,
        password,
      }
    );

    return {
      data: response.data,
      status: RESPONSE_STATUSES.SUCCESS,
    };
  } catch (error: any) {
    return {
      data: null,
      status: RESPONSE_STATUSES.FAILED,
    };
  }
};

export const requestAutoLogin = async ({ email }: {email: string}) => {
  try {
    const loginUrl = `${config.api}/api/v1/users/autologin`;
    const response = await axios.post<AutoLoginResponse>(
      loginUrl,
      {
        email,
      }
    );

    console.log("response - requestAutoLogin ",response);

    return {
      data: response.data,
      status: RESPONSE_STATUSES.SUCCESS,
    };
  } catch (error: any) {
    return {
      data: null,
      status: RESPONSE_STATUSES.FAILED,
    };
  }
};

export const autoLogin = async ({ token }: {token: string}) => {
  try {
    const loginUrl = `${config.api}/api/v1/users/autologin`;
    const response = await axios.get<AutoLoginResponse>(
      loginUrl,
      {
        headers: getLoggedInHeaders(token),
      }
    );

    console.log("response ",response);

    return {
      data: response.data,
      status: RESPONSE_STATUSES.SUCCESS,
    };
  } catch (error: any) {
    return {
      data: null,
      status: RESPONSE_STATUSES.FAILED,
    };
  }
};
