import { css } from "@emotion/react";

const styles = {
  container: css`
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    @media (min-width: 768px) {
      margin-top: 40px;
    }
  `,
  title: css`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  `,
  paragraph: css`
    font-weight: 200;
  `,
  formGroup: css`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 0;
  `,
  button: css`
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    &:disabled {
      opacity: 0.3;
    }
  `,
};

export default styles;
