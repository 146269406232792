import React from "react";
import { Journey } from "../../../../Auth/api/types";
import { styles } from "./JourneyHeader.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

type Props = {
  journey: Journey | null;
};

const JourneyHeader: React.FC<Props> = ({ journey }) => {
  const navigate = useNavigate();

  return (
    <>
      {journey && (
        <button
          css={styles.goalContainer}
          onClick={() => {
            navigate(`/journey/${journey._id}`);
          }}
        >
          <p css={styles.goalsText}>
            <span css={styles.goalsUnit}>Calories</span>{" "}
            {journey?.calorieIntake}
          </p>
          <p css={styles.goalsText}>
            <span css={styles.goalsUnit}>Weight</span> {journey?.goal.weight}
          </p>
          <p css={styles.goalsText}>
            <span css={styles.goalsUnit}>Date</span>{" "}
            {journey?.end && format(new Date(journey?.end), "dd.MM.yy")}
          </p>
        </button>
      )}
    </>
  );
};

export default JourneyHeader;
