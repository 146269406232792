import { css } from "@emotion/react";
import { theme } from "../../../../../global/styling/theming";

export const styles = {
    goalContainer: css`
      background: ${theme.colors.logoGreen};
      color: #000;
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      border: none;
      width: 100%;
      @media (min-width: 980px) {
        padding: 0 25%;
      }
    `,
    goalsText: css`
      font-family: ${theme.fontFamily.gill}, sans-serif;
      font-size: 1.125rem;
      font-weight: 100;
    `,
    goalsUnit: css`
      font-weight: 600;
    `,
};

