import React from "react";
import { css } from "@emotion/react";

const styles = {
    responseInput: css`
        padding: 1rem;
        font-size: 16px;
        margin-bottom: 20px;
        font-family: "Gill Sans";
        background: #D9D9D9;
        border: none;
        margin: 20px 0;
        min-height: 180px;
        width: auto;
    `,
};

type Props = {
    value?: string;
    onChange: (value: string) => void;
}


const TextArea = ({value, onChange}: Props) => {
    return (
        <textarea
            css={styles.responseInput}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            placeholder="Enter your response"
        />
    )
};

export default TextArea;