import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { format } from "date-fns";
import MacroCircles from "../../../components/components/MacroCircles";
import { useMeals } from "../state/context";
import { useAuth } from "../../Auth/state/context";
import { calculateDayTotals, calculateMealTotals, findMealsByDate } from "../utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../global/routing/routes";
import { CameraIcon } from "../../../components/icons/Icons";
import LoadingSpinner from "../../../components/Loader/Loader";
import { TrashIcon } from "../../CalorieTracker/Delete";

const DayOverview: React.FC = () => {
  const { meals, fetchMeals, deleteMeal, isDeleting } = useMeals();
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(token)
    fetchMeals(token);
  }, [])
  const today = new Date();
  const formattedDate = format(today, "d MMM yy"); // e.g., "1 Aug 24"

  const deleteAMeal = async(mealId: string) => {
    await deleteMeal(mealId);
    if(token)
    fetchMeals(token);
  }

  const goalCalories = 2200;
  
  console.log("meals ",meals);
  const mealsForDay = meals ? findMealsByDate(meals, today) : [];
  console.log("mealsForDay ",mealsForDay);
  
  const total = mealsForDay ? calculateDayTotals(mealsForDay) : null;

  const caloriePercentage = total ? (total.totalCalories / goalCalories) * 100 : null;

  return (
    <div css={containerStyle}>
      <header css={headerStyle}>
        <h1 css={currentDayHeader}>Today</h1>
        <p css={currentFormattedDate}>{formattedDate}</p>
      </header>
      <section css={caloriesSectionStyle}>
        <div css={caloriesHeaderStyle}>
          <p>Calories</p>
          {total &&          
            <p>
              {total.totalCalories} / {goalCalories}
            </p>
          }
        </div>
        <div css={progressBarStyle}>
          {caloriePercentage &&
            <div css={progressStyle(caloriePercentage)}></div>
          }
        </div>
        <div css={nutritionInfoStyle}>
          {total &&          
            <MacroCircles
              estimate={{
                items: [],
                total: {
                  calories: total.totalCalories,
                  protein: total.totalProtein,
                  carbs: total.totalCarbs,
                  fat: total.totalFat,
                },
              }}
            />
          }
        </div>
      </section>
      <section css={mealsSectionStyle}>
        <h2 css={mealsSectionHeader}>Meals</h2>
        {mealsForDay.map((meal, index) => {
          const mealValues = calculateMealTotals(meal);
          return (
            <div key={index} css={mealItemStyle}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <p css={mealNameStyle}>{meal.title}</p>
              {meal._id &&              
                <span style={{color: isDeleting ? "#efefef" : "#333" }} onClick={() => {
                  if(!isDeleting && meal._id) {
                    deleteAMeal(meal._id)
                  }
                }}>{TrashIcon}</span>
              }
            </div>
            <div css={mealInfoContainer}>
                <img src={meal.imageUrl} alt={meal.title} css={mealImageStyle} />
                <p css={mealCaloriesStyle}><span>{mealValues.totalCalories} </span><span css={mealCaloriesUnit}>kcal</span></p>
            </div>
          </div>
          )
        })}
      </section>
      <footer css={footerStyle}>
        <button css={cameraButtonStyle}
          onClick={() => {
            navigate("/"+ROUTES.PHOTO)
          }}
        >
          {CameraIcon}
        </button>
      </footer>
    </div>
  );
};

// Styles using Emotion
const containerStyle = css`
  background-color: #333;
  color: #fff;
  padding: 16px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter';
`;

const headerStyle = css`
  text-align: center;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  text-align: left;
  align-self: flex-start;
`;

const currentDayHeader = css`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
`;

const currentFormattedDate = css`
  font-size: 32px;
  font-weight: 300;
  color: rgb(255 255 255 / 60%);
  margin: 0;
  margin-left: 8px;
`;

const caloriesSectionStyle = css`
  width: 100%;
  margin-bottom: 16px;
`;

const caloriesHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const progressBarStyle = css`
  background-color: #555;
  border-radius: 10px;
  overflow: hidden;
  height: 8px;
  margin-bottom: 16px;
`;

const progressStyle = (percentage: number) => css`
  height: 100%;
  background-color: #3bf;
  width: ${percentage}%;
`;

const nutritionInfoStyle = css`
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  margin-top: 40px;
`;

const mealsSectionStyle = css`
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
`;

const mealsSectionHeader = css`
 font-size: 18px;
 padding-bottom: 8px;
 border-bottom: 1px solid #fff;
`;

const mealItemStyle = css`
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 8px;
  overflow: hidden;
  flex-direction: column;
  padding: 5px 10px;
  font-family: 'Inter';
`;

const mealImageStyle = css`
  width: 80px;
  height: 80px;
  object-fit: cover;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const mealInfoStyle = css`
  padding: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const mealNameStyle = css`
  margin: 0;
  font-family: 'Inter';
  margin-bottom: 3px;
  font-size: 1rem;
  color: #333;
`;

const mealInfoContainer = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const mealCaloriesStyle = css`
  font-size: 1.25rem;
  margin: 0;
  color: #333;
`;

const mealCaloriesUnit = css`
  color: black;
  opacity: 0.3;
`;

const footerStyle = css`
  margin-top: auto;
  padding-bottom: 16px;
`;

const cameraButtonStyle = css`
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #333;
  cursor: pointer;
`;

export default DayOverview;
