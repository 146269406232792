import React, { useEffect } from 'react';
import LoginContainer from '../containers/LoginContainer';
import { useAuth } from '../state/context';
import LoadingSpinner from '../../../components/Loader/Loader';
import RequireAuthentication from '../hooks/RequireAuthentication';


const SignOut = () => {

    const {logout} = useAuth();

    useEffect(() => {
        logout();
    },[])
    
    return (
        <>
            <RequireAuthentication />
            <div>
                <LoadingSpinner text='Signing out' />
            </div>
        </>
    )
};

export default SignOut;