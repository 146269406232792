import { css } from "@emotion/react";
import { theme } from "../../../global/styling/theming";

const styles = {
  title: css`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: ${theme.fontFamily.main}, sans-serif;
    text-align: left;
  `,
  form: css`
    display: flex;
    flex-direction: column;
  `,
  label: css`
    font-size: 1.5rem;
    margin-bottom: 8px;
    font-family: ${theme.fontFamily.main}, sans-serif;
    font-weight: 300;
    margin-top: 20px;
  `,
  input: css`
    padding: 8px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    margin-bottom: 12px;
    width: auto;
    
    font-family: ${theme.fontFamily.main}, sans-serif;
    background: #d9d9d9;
    font-size: 1rem;
    font-weight: 100;
  `,
  button: css`
    padding: 10px 40px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
    cursor: pointer;
    margin-top: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  `,
};

export default styles;
