import SignUpContainer from "../containers/SignUpContainer";

const SignUpPage = () => {
  return (
    <div>
      <SignUpContainer />
    </div>
  );
};

export default SignUpPage;
