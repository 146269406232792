import React from 'react';
import { CheckIn } from '../../CheckIns';
import { styles } from './SelectedCheckIn.css';
import { format } from 'date-fns';
import {Star} from '../../../../../global/icons/';
import SadFace from '../../../../../global/icons/SadFace.png';

type Props = {
    selectedCheckIn: CheckIn | null;
    isDisabled: boolean;
    deleteCheckIn: (id: string) => void;
    select: () => void;
}

const SelectedCheckIn: React.FC<Props> = ({selectedCheckIn, deleteCheckIn, isDisabled, select}) => {
  return (
    <>
        {selectedCheckIn && 
            <>
                <div css={styles.selectedCheckIn}>
                    <h2 style={{margin: 0, marginBottom: "20px"}}>Check In</h2>
                    <div css={styles.content}>
                        <div css={[styles.box, styles.boxImage]}>
                            {selectedCheckIn.isSuccess &&
                                <>
                                    <Star />
                                    <p>Congratulations! <br /> You were successful.</p>
                                </>
                            }
                            {!selectedCheckIn.isSuccess &&
                                <>
                                    <img src={SadFace} alt="Sad face" />
                                    <p>Too bad. <br /> You didnt adhere.</p>
                                </>
                            }
                        </div>
                        <div css={styles.box}>
                            <p><span css={styles.unit}>Calories:</span> {selectedCheckIn.calories} kcal</p>
                            <p><span css={styles.unit}>Date:</span> {format(new Date(selectedCheckIn.date), "dd.MM")}</p>
                            <p><span css={styles.unit}>Vekt:</span> {selectedCheckIn.weight} kg</p>
                        </div>
                    </div>
                    <div css={styles.buttons}>
                        <button
                            css={[styles.button, styles.deleteButton]}
                            disabled={isDisabled}
                            onClick={() => {
                                deleteCheckIn(selectedCheckIn.id);
                            }}
                            >
                            Delete
                        </button>
                        <button
                            css={[styles.button, styles.closeButton]}
                            onClick={() => {
                                select();
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 200, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
            </>
        }
    </>
  );
};

export default SelectedCheckIn;