import React from "react";
import styles from "./CheckInFeedback.css";
import { useCheckInFeedback } from "../state/context";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../global/routing/routes";

const Feedback = () => {
  const {response, isSuccess, date} = useCheckInFeedback();
  const navigate = useNavigate();

  const goToCheckIn = () => {
    navigate(`/${ROUTES.CHECKINS}`);
  }

  return (
    <div css={styles.container}>
      <h1 css={styles.title}>Feedback</h1>
      <p css={styles.date}>Date: {date}</p>
      <p css={styles.status}>Status: {isSuccess ? "Successful" : "Failure"}</p>
      <p css={styles.summary}>{response}</p>
      <button css={styles.button} onClick={() => {
        goToCheckIn();
      }}>OK!</button>
    </div>
  );
};

export default Feedback;
