import React from "react";
import { styles } from "./LandingPage.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../../global/routing/routes";
import CtaButton from "../../../components/CTAButton/CTAButton";

const Step = ({number, title, description}: {number: number, title: string, description: string}) => {
  return (
    <div className="Step" css={styles.steps.step.main}>
      <div css={styles.steps.step.container}>
        <span css={styles.steps.step.unit}>Step</span>
        <span css={styles.steps.step.number}>{number}</span>
      </div>
      <div css={styles.steps.step.contentContainer}>
        <h3 css={styles.steps.step.stepTitle}>{title}</h3>
        <p css={styles.steps.step.stepDescription}>
          {description}
        </p>
      </div>
    </div>
  );
};

function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const CoachingLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const goToSignUpPage = () => {
    // window.open("https://tally.so/r/nroqL5", "_blank");
    // @TODO - send to sign up page
    scrollToTop()
    navigate("/" + ROUTES.SIGNUP);
  };
  return (
    <div>
      <div>
        <div css={styles.hero}>
          <div css={styles.heroContainer}>
            <div css={styles.heroTextContent}>
              <h1 css={styles.heroTitle}>
                Your <strong>AI weightloss</strong>
                <br /> accountability coach
              </h1>
              <p css={styles.heroSubTitle}>
                With FitMentor, we hold you accountable <br /> to your personal
                nutrition goals.
              </p>
              {/*<p css={styles.pricing}>20 $ per month</p>*/}
              <div css={styles.pricingContainer}>
                <span css={styles.pricingConditions}>First month free</span>
                <CtaButton
                  css={styles.ctaButton}
                  onClick={goToSignUpPage}
                  text="Get your personal FitMentor"
                />
                <span css={styles.pricingConditions}>
                  No credit card required
                </span>
              </div>
            </div>
            <div css={styles.screenshot.container}>
              <img
                css={styles.screenshot.image}
                src="/MobilePreview.png"
                alt="Screenshot"
              />
            </div>
          </div>
          <div css={styles.features}>
            <div css={styles.feature}>
              <h3 css={styles.featureHeader}>Proven success</h3>
              <p css={styles.featureParagraph}>
                Research has shown that, someone holding you accountable doubles
                your chances of losing weight.{" "}
              </p>
            </div>
            <div css={styles.feature}>
              <h3 css={styles.featureHeader}>Personal coach</h3>
              <p css={styles.featureParagraph}>
                Your FitMentor coach will give you personalized feedback daily.
              </p>
            </div>
            <div css={styles.feature}>
              <h3 css={styles.featureHeader}>Reach your goals</h3>
              <p css={styles.featureParagraph}>
                We help you build the habits, that gets you the results.
              </p>
            </div>
          </div>
        </div>
        <div css={styles.benefits.mainContainer}>
          {/*                 
                    <div>
                        <div css={styles.videoMessage.container}>
                            <div>
                                <video  css={styles.videoMessage.video} src="/small_Edited.mp4" autoPlay ></video>
                            </div>
                            <p css={styles.videoMessage.subtitle}>Reach your weightloss goals</p>
                            <h2 css={styles.videoMessage.title}>Get results with your<br/> personal accountability coach</h2>
                        </div>
                    </div>
                */}
          <div css={styles.howTo}>
            {/* Steps */}
            <h3 css={styles.steps.header}>
              How to lose weight with FitMentor ?
            </h3>
            <div className="Steps" css={styles.steps.container}>
              <Step number={1} title="Pick a calorie goal" description="Calculate your ideal calorie deficit. Then pick a reasonable calorie goal that you can stick to. " />
              <Step number={2} title="Check in daily" description="Everyday your Coach will check in with you, and give you feedback and encouragement." />
              <Step number={3} title="Lose weight" description="Once you get consistent with your diet, you will begin to lose weight consistently as well." />
            </div>
          </div>
          <h3 css={styles.steps.header}>What you get with FitMentor ?</h3>
          <div css={styles.benefits.container}>
            <div css={styles.benefits.benefit}>
              <img
                css={styles.benefits.image}
                src="/personal_support.jpg"
                alt="placeholder"
              />
              <div css={styles.benefits.text}>
                <h4 css={styles.benefits.title}>
                  Personal weightloss <br />
                  support available 24/7
                </h4>
                <p css={styles.benefits.paragraph}>
                  Your personal FitMentor coach is there for you, when{" "}
                  <strong>you need it.</strong> No matter the time of the day,
                  you will get instant assistance and support to stay on your
                  path.
                </p>
              </div>
            </div>
            <div css={styles.benefits.benefit}>
              <img
                css={styles.benefits.image}
                src="/notification.jpg"
                alt="placeholder"
              />
              <div css={styles.benefits.text}>
                <h4 css={styles.benefits.title}>
                  Daily checkins to <br />
                  keep you on track
                </h4>
                <p css={styles.benefits.paragraph}>
                  With FitMentor we will check in with you everyday. To help you
                  stay consistent, so you consistent results. We will make sure
                  you stay on the diet, and adjust it to ensure it is easy to
                  maintain and make it a sustainable lifestyle.
                </p>
              </div>
            </div>
          </div>
          <div css={styles.benefits.container}>
            <div css={styles.benefits.benefit}>
              <img
                css={[styles.benefits.image, styles.benefits.personalImage]}
                src="/profile.jpeg"
                alt="placeholder"
              />
              <div css={styles.benefits.text}>
                <h4 css={styles.benefits.title}>
                  Personalized tips to
                  <br />
                  help you solve problems
                </h4>
                <p css={styles.benefits.paragraph}>
                  Based on your experience, struggles and issues that arise. You
                  will get tailored and personal tips to overcome them and
                  continue to make progress toward your healthy and fit
                  lifestyle.
                </p>
              </div>
            </div>
            <div css={[styles.benefits.benefit, styles.benefits.lastBenefit]}>
              <div
                css={[styles.benefits.text, styles.benefits.lastBenefitText]}
              >
                <h4 css={styles.benefits.title}>
                  Get your personal fitness AI coach today
                </h4>
                <button
                  onClick={goToSignUpPage}
                  css={[styles.ctaButton, styles.benefits.lastBenefitCTA]}
                >
                  Start today
                </button>
              </div>
            </div>
            {/* Get your personal fitness AI coach today */}
          </div>
        </div>
      </div>
      <div css={styles.footer}>
        <p>FitMentor</p>
        <a href={ROUTES.PRIVACY_POLICY}>Privacy Policy</a>
      </div>
    </div>
  );
};

export default CoachingLandingPage;
