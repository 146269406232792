import { css } from "@emotion/react";
import { theme } from "../../global/styling/theming";

const styles = {
  label: css`
    font-size: 1.5rem;
    margin-bottom: 8px;
    font-family: ${theme.fontFamily.main}, sans-serif;
    font-weight: 300;
    margin-top: 20px;
  `,
  input: css`
    padding: 8px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    margin-bottom: 12px;
    width: auto;
    
    font-family: ${theme.fontFamily.main}, sans-serif;
    background: #d9d9d9;
    font-size: 1rem;
    font-weight: 100;
  `,
};

export default styles;