import React from "react";
import styles from "./GoalCreated.css";

type Props = {
  coachName: string;
};

const CongratulationsScreen = ({ coachName }: Props) => {
  return (
    <div css={styles.container}>
      <h1 css={styles.heading}>Congratulations!</h1>
      <p css={styles.message}>
        You have been awarded a coach. Your assigned coach is:
      </p>
      <h2 css={styles.coachName}>{coachName}</h2>
    </div>
  );
};

export default CongratulationsScreen;
