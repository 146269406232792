import config from "../../../config";
import { getLoggedInHeaders } from "../../../global/api/utils";
import { MEAL } from "../types";

export const save = async (meal: MEAL, token: string) => {
  const response = await fetch(`${config.api}/api/v1/meal/meals`, {
    method: "POST",
    body: JSON.stringify(meal),
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to save meal");
  }

  return response.json();
};


export const getMeals = async (token: string) => {
  const response = await fetch(`${config.api}/api/v1/meal/meals`, {
    method: "GET",
    headers: getLoggedInHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Failed to save meal");
  }

  return response.json();
};

export const deleteSingleMeal = async (mealId: string) => {
  const response = await fetch(`${config.api}/api/v1/meal/meals/${mealId}`, {
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error("Failed to save meal");
  }

  return response.json();
}