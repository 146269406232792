import React, { useState } from "react";
import styles from "./CheckInFailedFeedback.css";
import { useCheckInFeedback } from "../state/context";
import { useAuth } from "../../Auth/state/context";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../global/routing/routes";
import FailedFeedbackStepOne from "./components/FailedFeedbackStepOne/FailedFeedbackStepOne";
import FailedFeedbackStepTwo from "./components/FailedFeedbackStepTwo/FailedFeedbackStepTwo";
import FeedbackContainer from "../components/FeedbackContainer";

enum FAILED_FEEDBACK_STEPS {
  STEP_ONE = "STEP_ONE",
  STEP_TWO = "STEP_TWO",
}

const CheckInFailedFeedback = () => {
  const { response, isSuccess, date, id } = useCheckInFeedback();
  const { token } = useAuth();
  const [step, setStep] = useState<FAILED_FEEDBACK_STEPS>(
    FAILED_FEEDBACK_STEPS.STEP_ONE
  );
  const navigate = useNavigate();

  const goToCheckIn = () => {
    navigate(`/${ROUTES.CHECKINS}`);
  };

  return (
    <FeedbackContainer>
      <h1 css={styles.title}>Check in</h1>
      <div css={styles.details}>
        <p css={[styles.date, styles.detail]}>
          <strong>Date:</strong> {date}
        </p>
        <p css={[styles.status, styles.detail]}>
          <strong>Status:</strong>
          <div
            style={{display: "flex", alignItems: "center"}}
          >
            <div
              css={[
                styles.checkInStatus,
                isSuccess ? {} : styles.checkInStatusFailure,
              ]}
              style={{
                marginRight: "8px",
              }}
            />
            {isSuccess ? "Success" : "Failed"}
          </div>
        </p>
      </div>
      {step === FAILED_FEEDBACK_STEPS.STEP_ONE && (
        <FailedFeedbackStepOne
          goToNext={() => {
            // Go to next step or back to check in
            // setStep(FAILED_FEEDBACK_STEPS.STEP_TWO)
            setStep(FAILED_FEEDBACK_STEPS.STEP_TWO);
          }}
        />
      )}
      {step === FAILED_FEEDBACK_STEPS.STEP_TWO && (
        <FailedFeedbackStepTwo goToNext={() => {}} />
      )}
    </FeedbackContainer>
  );
};

export default CheckInFailedFeedback;
