import { css } from "@emotion/react";
import React from "react";
import { theme } from "../../../../global/styling/theming";

interface ButtonProps {
  onClick: () => void;
  text: string;
}

const styling = css`
  background: #a5ff5e;
  font-family: ${theme.fontFamily.main}, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #333;
  border: none;
  padding: 20px;
  border-radius: 100px;
  font-weight: 300;
  font-size: 20px;
`;

const CtaButton: React.FC<ButtonProps> = ({ onClick, text }) => {
  return <button onClick={onClick} css={styling}>{text}</button>;
};

export default CtaButton;
