import { format, setDate, setSeconds, setMinutes, setHours, setMilliseconds } from 'date-fns';
import { CheckInRequestResponse } from './CheckIns';
import { CheckIn } from './CheckIns';
import dayjs from 'dayjs';

export const clearDateValues = (date: string): string => {
    const parsedDate = setMilliseconds(setHours(setMinutes(setSeconds(setDate(new Date(date), 1),0),0),0),0);
    const clearedDate = parsedDate.toISOString();

    return clearedDate;
}


export const listMonthsAndYears = (dates: string[]): string[] => {
    const uniqueMonthsAndYears: string[] = [];
    const clearDates: string[] = [];

    dates.forEach(date => {
        const monthAndYear = format(new Date(date), 'MMMM yyyy');
        if (!uniqueMonthsAndYears.includes(monthAndYear)) {
            const clearDate = clearDateValues(date);
            clearDates.push(clearDate);
            uniqueMonthsAndYears.push(monthAndYear);
        }
    });
    return clearDates
};

export const formatCheckIns = (checkIns: CheckInRequestResponse[]): CheckIn[] => {
    return checkIns.map((checkIn) => {
      return {
        id: checkIn._id,
        date: checkIn.date,
        calories: checkIn.calorieIntake,
        isSuccess: checkIn.isSuccess,
        weight: checkIn.weight,
      };
    }).sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf())
  };
  