import React from "react";
import { css } from "@emotion/react";

interface Props {
  message?: string | null;
  success?: boolean;
  error?: boolean;
}

const styles = {
  message: css`
    color: white;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
  `,
  success: css`
    background-color: #0daa4c;
  `,
  failed: css`
    background-color: #d66e57;
  `,
};

const StatusMessage: React.FC<Props> = ({ message, success, error }) => {
  if (!message) return null;
  return <div css={[styles.message, error ? styles.failed : {}, success ? styles.success : {}]}>{message}</div>;
};

export default StatusMessage;
