import { css } from "@emotion/react";
import { theme } from "../../../../../global/styling/theming";

const DESKTOP_BREAKPOINT  = "1700px";

export const styles = {
    header: css`
      margin: 0.25rem;
      font-family: ${theme.fontFamily.main}, sans-serif;

      text-transform: uppercase;
      color: #9DACB5;
      font-family: 'Inter', sans-serif;
      letter-spacing: 0.4rem;
      width: calc(100% + 0.4rem);
      font-size: 0.75em;
      line-height: 1.125;
      font-weight: 300;
    `,
    mainSectionTitle: css`
        width: calc(100% + 0.4rem);
        font-size: 3.5em;
        line-height: 1.25;
        font-weight: 500;
    `,
    sectionMessage: css`
        font-size: 1.5em;
        line-height: 2;
        font-weight: 200;
    `,
    section: css`
        display: flex;
    `,
    halfSide: css`
        width: 50%;
    `,
    textContentSide: css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    `,
    imageContentSide: css`
        display: flex;
        justify-content: flex-end;
    `,

    hero: css`
        background: #F4F4F4;
        padding: 40px;
        display: flex;
        flex-direction: column;
    `,
    heroTextContent: css`
        @media (min-width: 768px) {
            width: 50%;
        }
        @media (min-width: ${DESKTOP_BREAKPOINT}) {
            margin-left: 5%;
            ${`//margin-left: 15%;//`}
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    `,
    heroTitle: css`
        color: #333;
        font-family: 'Inter', sans-serif;
        font-size: 26px;
        font-weight: 400;
        @media (min-width: 1200px) {
            font-size: 64px;
        }
        @media (min-width: 768px) {
            font-size: 52px;
        }
    `,
    heroSubTitle: css`
        font-size: 1rem;
        color: #333;
        font-family: 'Inter', sans-serif;
        font-weight: 300;
        @media (min-width: 768px) {
            font-size: 32px;
        }
        @media (min-width: 768px) {
            font-size: 28px;
        }
    `,
    pricingContainer: css`
        display: flex;
        flex-direction: column;
        width: 310px;
        margin: auto;
    `,
    pricing: css`
        color: #333;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-size: 12px;
        text-align: center;
        margin-bottom: 10px;
        @media (min-width: 768px) {
            font-size: 1rem;
        }
    `,
    pricingConditions: css`
        text-align: center;
        display: block;
        margin: 10px auto;
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 100;
    `,
    ctaButton: css`
        background: #A5FF5E;
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 2rem;
        color: #333;
        border: none;
        padding: 20px;
        border-radius: 100px;
        font-weight: 300;
        font-size: 20px;
    `,
    features: css`
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        flex-direction: column;
        @media (min-width: 768px) {
            flex-direction: row;
        }
        @media (min-width: ${DESKTOP_BREAKPOINT}) {
            padding: 0 20%;
        }
    `,
    feature: css`
        background: white;
        padding: 20px;
        width: 25%;
        text-align: center;
        border-radius: 20px;
        @media (max-width: 768px) {
            margin-bottom: 20px;
            width: unset;
        }
    `,
    featureHeader: css`
        font-weight: 500;
    `,
    featureParagraph: css`
        font-weight: 200;
    `,
    heroContainer: css`
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
            flex-direction: column;
        }
        @media (min-width: 920px) {
            justify-content: space-around;
        }
        @media (min-width: ${DESKTOP_BREAKPOINT}) {
            padding: 0 20%;
            justify-content: space-between;
        }
    `,
    screenshot: {
        container: css`
            display: flex;
            align-items: center;
            justify-content: center;
            @media (min-width: ${DESKTOP_BREAKPOINT}) {
                margin-right: 15%;
            }
        `,
        image: css`
            margin-top: 40px;
            @media (max-width: 768px) {
                width: 250px;
            }
            @media (min-width: 768px) {
                margin-top: unset;
                width: 60%;
            }
        `,
    },
    videoMessage: {
        container: css`
            background: rgb(0 0 0 / 47%);
            position: relative;
            width: 1000px;
            height: 400px;
            margin: auto;
            border-radius: 20px;
            margin-top: 40px;
            margin-bottom: 40px;
        `,
        video: css`
            width: 100%;
            height: 400px;
            position: relative;
            border-radius: 20px;
            object-fit: cover;
        `,
        subtitle: css`
            position: absolute;
            color: #B8B8B8;
            text-transform: uppercase;
            font-size: 24px;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -50%);
        `,
        title: css`
            position: absolute;
            text-align: center;
            color: white;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
        `,
    },
    howTo: css`
        padding: 0 20px;
    `,
    steps: {
        header: css`
            font-family: 'Inter', sans-serif;
            font-size: 26px;
            text-align: center;
            margin: 3rem;
        `,
        container: css`
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 20px;
            @media (min-width: 768px) {
                flex-direction: row;
                padding: unset;
            }
        `,
        step: {
            main: css`
                margin-top: 30px;
                @media (min-width: 768px) {
                    margin-top: unset;
                    width: 30%;
                }
            `,
            container: css`
                font-family: 'Inter';
                margin-left: 30%;
                position: relative;
                top: 14px;
            `,
            unit: css`
                background: #D9D9D9;
                font-size: 28px;
                padding: 8px 16px;
                font-weight: 300;
                border-radius: 8px;
            `,
            number: css`
                background: #D9D9D9;
                border-radius: 40px;

                font-size: 32px;
                padding: 16px 26px;
                position: relative;
                left: -16px;
                z-index: 197;
            `,
            contentContainer: css`
                background: #F4F4F4;
                text-align: center;
                padding: 20px;
                border-radius: 8px;
            `,
            stepTitle: css`
                font-family: 'Comfortaa';
                font-weight: 600;
            `,
            stepDescription: css`
                font-family: 'Comfortaa';
                font-weight: 100;
            `,
        }
    },
    benefits: {
        mainContainer: css`
            @media (min-width: ${DESKTOP_BREAKPOINT}) {
                padding: 0 20%;
            }
        `,
        container: css`
            display: flex;
            margin-top: 40px;
            padding: 0 40px;
            justify-content: space-between;
            flex-direction: column;
            @media (min-width: 768px) {
                margin-bottom: 40px;
                flex-direction: row;
            }
        `,
        benefit: css`
            background: #F2F2F2;
            display: flex;
            align-items: center;
            border-radius: 20px;
            
            flex-direction: column;
            padding: 20px;
            margin-bottom: 20px;
            @media (min-width: 768px) {
                margin-bottom: unset;
                padding: unset;
                width: 48%;
                flex-direction: row;
            }
        `,
        image: css`
            width: 170px;
            border-radius: 20px;
            height: 252px;
            margin: 20px;
        `,
        personalImage: css`
            height: auto;
        `,
        text: css`
            padding-right: 20px;
        `,
        title: css`
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            color: #333;
            font-size: 1.375rem;
            margin: 0;
        `,
        paragraph: css`
            color: #5C5C5C;
            font-family: 'Inter', sans-serif;
            font-size: 1rem;
        `,
        lastBenefit: css`
            justify-content: center;
            width: 28%;
            @media (max-width: 768px) {
                margin-top: 20px;
                width: unset;
            }
        `,
        lastBenefitText: css`
            padding: 20px;
            text-align: center;
        `,
        lastBenefitCTA: css`
            margin-bottom: 30px;
            margin-top: 20px;
        `,
    },
    footer: css`
        background: #333;
        width: 100%;
        display: flex;
        color: white;
        align-items: center;
        justify-content: space-between;
        p {
            padding: 20px;
            margin: 0;
        }
        a {
            color: white;
            text-decoration: none;
            padding-right: 20px;
        }
    `
}
