import React, { useEffect, useState } from "react";
import styles from "../../CheckInFailedFeedback.css";
import { useNavigate } from "react-router-dom";
import { useCheckInFeedback } from "../../../state/context";
import { useAuth } from "../../../../Auth/state/context";
import { followUpCheckIn } from "../../../api";
import { ROUTES } from "../../../../../global/routing/routes";
import TextArea from "../../../../../components/form/TextArea";


type Props = {
    goToNext: () => void;
}

const FailedFeedbackStepTwo = ({goToNext}: Props) => {
  const { response, isSuccess, date, id } = useCheckInFeedback();
  const { token } = useAuth();
  const [failureReason, setFailureReason] = useState("");
  const [sendingResponse, setSendingResponse] = useState(false);
  const [failureFeedback, setFailureFeedback] = useState("");
  const [audioFile, setAudioFile] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleResponseChange = (value: string) => {
    setFailureReason(value);
  };

  const giveFailedFeedback = async (token: string, id: string) => {
    try {
      setSendingResponse(true);
      const followUp = await followUpCheckIn({
        token,
        id,
        answer: failureReason,
        question: "Why did you fail ?",
        getCoachFeedback: true
      });
      setFailureFeedback(followUp.completions);
      if(followUp.checkIn.audioFile) {
        setAudioFile(followUp.checkIn.audioFile);
      }
      setSendingResponse(false);
    } catch (err: any) {
      setSendingResponse(false);
      // console.error("Failed to follow up:", err, err.message);
    }
  };

  const handleSubmit = () => {
    if (token && id) {
      giveFailedFeedback(token, id);
    }
  };

  const goToCheckIn = () => {
    navigate(`/${ROUTES.CHECKINS}`);
  };

  const failed = !isSuccess;
  const failureFeedbackSet = failureFeedback.length > 0;
  return (
    <div css={styles.feedbackContainer}>
      {failureFeedbackSet && (
        <>
          {audioFile && <audio css={styles.stepTwo.audioFile} src={audioFile} autoPlay controls />}
          <div css={styles.stepTwo.feedbackText}>{failureFeedback}</div>
        </>
      )}
      {!failureFeedbackSet && failed && (
        <>
          <p css={styles.summary}>What will you do differently to address those issues ?</p>
          <TextArea value={failureReason} onChange={handleResponseChange} />
        </>
      )}
      <button
        css={styles.button}
        disabled={failureReason.length === 0 || sendingResponse}
        onClick={() => {
          if (failureFeedbackSet) {
            goToCheckIn();
          } else {
            handleSubmit();
          }
        }}
      >
        {sendingResponse && 
          "Sending..."
        }
        {!sendingResponse && 
          <>
            {failureFeedbackSet ? "Ok" : "Submit answer"}
          </>
        }
      </button>
    </div>
  );
};

export default FailedFeedbackStepTwo;
