import React from "react";
import { css } from "@emotion/react";
import { theme } from "../../../../global/styling/theming";

const styles = {
  details: css`
    display: flex;
    flex-direction: column;
    width: 200px;
  `,
  detail: css`
    display: flex;
    justify-content: space-between;
    font-weight: 300;
  `,
  date: css`
    font-size: 1.5rem;
    margin-bottom: 10px;
  `,
  status: css`
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-top: 0;
  `,
  checkInStatus: css`
    background: ${theme.colors.status.success};
    width: 20px;
    height: 20px;
    border-radius: 40px;
  `,
  checkInStatusFailure: css`
    background: ${theme.colors.status.failed};
  `,
};

interface Props {
  // Add any props here
  isSuccess: boolean;
  date: string;
}

const FeedbackHeader: React.FC<Props> = ({ isSuccess, date }) => {
  return (
    <div css={styles.details}>
      <p css={[styles.date, styles.detail]}>
        <strong>Date:</strong> {date}
      </p>
      <p css={[styles.status, styles.detail]}>
        <strong>Status:</strong>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            css={[
              styles.checkInStatus,
              isSuccess ? {} : styles.checkInStatusFailure,
            ]}
            style={{
              marginRight: "8px",
            }}
          />
          {isSuccess ? "Success" : "Failed"}
        </div>
      </p>
    </div>
  );
};

export default FeedbackHeader;
