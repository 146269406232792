import { css } from "@emotion/react";
import { theme } from "../../../global/styling/theming";

const styles = {
  title: css`
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: ${theme.fontFamily.main}, sans-serif;
    text-align: left;
  `,
  details: css`
    display: flex;
    flex-direction: column;
    width: 200px;
  `,
  detail: css`
    display: flex;
    justify-content: space-between;
    font-weight: 300;
  `,
  date: css`
    font-size: 1.5rem;
    margin-bottom: 10px;
  `,
  status: css`
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-top: 0;
  `,
  checkInStatus: css`
    background: ${theme.colors.status.success};
    width: 20px;
    height: 20px;
    border-radius: 40px;
  `,
  checkInStatusFailure: css`
    background: ${theme.colors.status.failed};
  `,
  feedbackContainer: css`
    max-width: 650px;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
  `,
  summary: css`
    font-size: 1.5rem;
    font-family: ${theme.fontFamily.gill};
    font-weight: 300;
    margin-bottom: 0px;
  `,
  feedbackReplyContainer: css`
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    padding: 20px;
    justify-content: center;
    display: flex;
  `,
  button: css`
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    align-self: flex-start;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &:disabled {
      opacity: 0.3;
    }
  `,
  stepTwo: {
    audioFile: css`
      width: 100%;
    `,
    feedbackText: css`
      margin-top: 20px;
      white-space: pre-line;
      font-size: 1.5rem;
      font-weight: 100;
      color: #333;
    `,
  }
};

export default styles;
