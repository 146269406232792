import { css } from "@emotion/react";
import { theme } from "../../../../../global/styling/theming";

export const thStyles = css`
  padding: 12px;
  background: none;
  font-weight: 300;
  color: #5c5c5c;
  font-size: 24px;
`;

export const tdStyles = css`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

export const tableRow = css`
  text-align: center;
`;

export const styles = {
  statusTextColor: {
    success: css`
      color: ${theme.colors.status.success};
    `,
    failure: css`
      color: ${theme.colors.status.failed};
    `,
  },
  selectedTR: css`
    background: #d9d9d9;
    opacity: 0.2;
  `,
  checkInStatus: css`
    background: ${theme.colors.status.success};
    width: 20px;
    height: 20px;
    border-radius: 40px;
  `,
  checkInStatusFailure: css`
    background: ${theme.colors.status.failed};
  `,
  statusTd: css`
    justify-content: center;
    display: flex;
  `,
};
