import { css } from "@emotion/react";
import { theme } from "../../../global/styling/theming";

// Define Emotion CSS styles
export const tableStyles = css`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
  margin-bottom: 4rem;
`;

export const thStyles = css`
  padding: 12px;
  background: none;
  font-weight: 300;
  color: #5C5C5C;
  font-size: 24px;
`;

export const tdStyles = css`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

export const tableRow = css`
  text-align: center;
`;

export const tableRowSuccess = css`
`;

export const tableRowFailure = css`
`;

export const styles = {
  container: css`
    padding: 20px;
    @media (min-width: 980px) {
      width: 750px;
      margin: auto;
    }
  `,
  header: css`
    margin: 0.25rem;
    font-family: ${theme.fontFamily.main}, sans-serif;
  `,
  buttonContainer: css`
    position: sticky;
    bottom: 0;
    background: white;
    width: 100%;
    padding: 20px 0;
    z-index: 10;
  `,
  button: css`
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;

    margin: auto;
    display: flex;
  `,
}