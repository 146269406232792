import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { createGoal, updateGoal } from "../api";
import { useAuth } from "../../Auth/state/context";
import StatusMessage from "../../../components/StatusMessage";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../global/routing/routes";
import Input from "../../../components/form/Input";
import styles from "./GoalForm.css";

export type Goal = {
  calorieIntake: string;
  end: string;
  weight: string;
  why: string;
};

export type Props = {
  initialValues?: Goal;
  existingId?: string
};

const GoalForm: React.FC<Props> = ({initialValues, existingId}: Props) => {
  const [calorieIntake, setCalorieIntake] = useState(initialValues?.calorieIntake || "");
  const [end, setEnd] = useState(initialValues?.end || "");
  const [weight, setWeight] = useState(initialValues?.weight || "");
  const [why, setWhy] = useState(initialValues?.why || "");
  const [error, setError] = useState("");

  const isEdit = !!existingId;

  const {token} = useAuth();
  const navigate = useNavigate();

  const mutation = useMutation(createGoal);
  const mutationUpdate = useMutation(updateGoal);

  useEffect(() => {
    if(mutation.isSuccess) {
      navigate(`/${ROUTES.CHECKINS}`)
    }
    if(mutationUpdate.isSuccess) {
      navigate(`/${ROUTES.CHECKINS}`)
    }
  })

  useEffect(() => {
    if(initialValues) {
      setCalorieIntake(initialValues.calorieIntake);
      setWeight(initialValues.weight);
    }
    if(initialValues?.end) {
      setEnd(format(new Date(initialValues?.end), "yyyy-MM-dd"));
    }
  }, [initialValues]);

  const sendData = async (formData: Goal, token: string) => {
    try {
      await mutation.mutateAsync({goal: formData, token});
      console.log("Goal created successfully");
    } catch (err: any) {
      console.error("Failed to create goal:", err.message);
      setError(err.message);
    }
  };

  const updateData = async (formData: Goal, token: string, goalId: string) => {
    try {
      await mutationUpdate.mutateAsync({goal: formData, token, goalId});
      console.log("Goal updated successfully");
    } catch (err: any) {
      console.error("Failed to update goal:", err.message);
      setError(err.message);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Perform your submit logic here
    const goal = {
      calorieIntake,
      end,
      weight,
      why,
    };
    // console.log(" Create goal ", goal);
    if(token) {
      if(isEdit && existingId) {
        updateData(goal, token, existingId);
        return
      }
      sendData(goal, token);
    } else {
      // set error
      setError("Could not set goal")
    }
  };

  const isValid = calorieIntake.length > 0 && end.length > 0 && weight.length > 0;

  return (
    <div css={styles.container}>
      <h1 css={styles.title}>{isEdit ? "Edit goals" : "Set up goals"}</h1>
      <p css={styles.paragraph}>Here is a <a href="https://www.calculator.net/calorie-calculator.html" target="_blank">reasonable calorie calculator</a>. Aim for moderate or mild weight loss.</p>
      <StatusMessage message={error} error={!!error} />
      <form onSubmit={handleSubmit}>
        <div css={styles.formGroup}>
          <Input
            label="Calorie Intake"
            htmlFor="calorieIntake"
            type="number"
            id="calorieIntake"
            placeholder="2500 kcal"
            value={calorieIntake}
            onChange={(e) => setCalorieIntake(e.target.value)}
          />
        </div>
        <div css={styles.formGroup}>
          <Input
            label="End Date"
            htmlFor="endDate"
            type="date"
            id="endDate"
            placeholder="10/10/2023"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
        </div>
        <div css={styles.formGroup}>
          <Input
            label="Goal weight"
            htmlFor="weight"
            type="number"
            id="weight"
            placeholder="in kg"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
        </div>
        <div css={styles.formGroup}>
          <Input
            label="Why are you doing this ?"
            htmlFor="why"
            type="text"
            id="why"
            placeholder="Be clear and honest"
            value={why}
            onChange={(e) => setWhy(e.target.value)}
          />
        </div>
        <button css={styles.button} type="submit" disabled={!isValid || mutation.isLoading}>
          {!mutation.isLoading && isEdit ? "Update" : "Create"}
          {mutation.isLoading && "Laster..."}
        </button>
      </form>
    </div>
  );
};

export default GoalForm;
