import React, { useEffect } from "react";
import { loginUser, registerUser } from "../api";
import SignupForm, { SignUpFormData } from "../components/SignUp";
import { RESPONSE_STATUSES } from "../../../global/api/types";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../global/routing/routes";
import config from "../../../config";
import FeedbackLink from "../../../components/FeedbackLink";
import PhotoEstimatorLandingPage from "../../Marketing/pages/LandingPages/PhotoEstimator/LandingPage";
import { setLocationToBilling } from "../../Subscription/utils";
import StatusMessage from "../../../components/StatusMessage";
import { useAuth } from "../state/context";

const SignUpContainer = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [message, setMessage] = React.useState<string | null>(null);

  useEffect(() => {
    if(config.FEATURES.CHARGE_NOW) {
      // setLocationToBilling();
    }
  }, []);

  const clearMessage = () => {
    setMessage(null);
  };

  const trackSignUp = () => {
    // @ts-ignore
    if(rdt) {
      // @ts-ignore
      rdt('track', 'SignUp', {
        "currency": "USD",
        "value": 0
    });
    }
  }

  const autoLogin = async(email: string, password: string) => {
    const { status, data } = await loginUser({email, password});
    if(data) {
      login({
        token: data.user.token, 
        email: data.user.email, 
        name: data.user.name,
        id: data.user.id,
        is_confirmed: data.user.is_confirmed,
      }, data.journey);
      navigate("/"+ROUTES.PHOTO);
    } else {
      setMessage("Could not automatically log you in")
      navigate("/"+ROUTES.LOGIN);
    }
  }

  const handleSubmit = async (formData: SignUpFormData) => {
    clearMessage();
    const result = await registerUser(formData);
    const isSuccess = result.status === RESPONSE_STATUSES.SUCCESS;
    const referralIsBuildspace = formData.referralCode === "buildspace";
    if (isSuccess) {
      if(referralIsBuildspace) {
        autoLogin(formData.email, formData.password);
      } else {
        trackSignUp();
        setLocationToBilling();
      }
    } else {
      setMessage("Could not register user");
    }
  };

  return (
    <>
      <StatusMessage message={message} error={false} />
      <SignupForm onSubmit={handleSubmit} />
      <div style={{
        textAlign: "center",
        margin: "20px"
      }}>
        <FeedbackLink isDark={false} />
      </div>
      <PhotoEstimatorLandingPage />
    </>
  );
};

export default SignUpContainer;
